import React, { useState, useMemo, useCallback, useEffect, MutableRefObject } from 'react';
import { useApolloClient, gql, useQuery } from '@apollo/client';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useBridgeApi, useLogger, useDispatch } from '../../Hooks';
import useVenueManager from 'Hooks/useVenueManager';
import View from './view';
import useI18n from '../../i18n';
import { useQuantity } from '../../Hooks/ItemDetails';
import { useVariations } from '../../Components/Variations';
import {
  GetItem,
  GetItem_item_upsales,
  GetItem_item_appliedVariations,
  GetItem_item_defaultVariations,
  currentItemFragment,
  currentItemFragment_image,
  currentItemFragment_titleLang,
  currentItemFragment_descriptionLang,
  GetItemVariables,
} from './__queries__';
import { ITEM_QUERY } from './getEntryCheckoutQuery';
import { useStorage } from 'Components/Storage';
import { useShoppingCartApi, useShoppingCart } from 'Components/ShoppingCartUniverse';
import useEntryFinder from 'Hooks/useEntryFinder';
import { checkOutOfStock, getItemMaxQuantity } from 'utils/inventory';

import { useConfig } from 'Components/ConfigProvider';

import { ALLERGEN } from '../../../__queries__';
import { setLoginModal } from '../../actions';

export const SCENES = {
  ITEM: 'ITEM',
  UPSALES: 'UPSALES',
};

export type UpsalesQuantityItem = { id: string; quantity: number; maxQuantity?: number };
export type UpsalesQuantity = { id: string; items: UpsalesQuantityItem[] };

interface LightEntry {
  id: string;
  price: number;
  item: {
    id: string;
    image: currentItemFragment_image;
    titleLang: currentItemFragment_titleLang;
    descriptionLang: currentItemFragment_descriptionLang;
    upsales?: GetItem_item_upsales[];
    appliedVariations?: GetItem_item_appliedVariations[];
    defaultVariations?: GetItem_item_defaultVariations[];
    modificationGroups: string[];
    allergens: ALLERGEN[];
  };
}

interface Props {
  /**
   * changeTitle
   */
  changeTitle?: (text: string) => void;
  /**
   * changeOnBackFunc
   */
  changeOnBackFunc?: (callback: (() => void) | undefined) => void;
  /**
   * changeShowOnScroll
   */
  changeShowOnScroll?: (scrollSize: number | false) => void;
  /**
   * contentRef
   */
  contentRef?: MutableRefObject<HTMLDivElement>;
}

/**
 * Item scene
 */
const Item = ({ changeTitle, changeOnBackFunc, changeShowOnScroll, contentRef }: Props) => {
  const config = useConfig();
  const dispatch = useDispatch();
  const logger = useLogger('item');
  const [storageData] = useStorage();
  const params = useParams<{ item_id: string }>();
  const venueParams = useParams<{ venue_id?: string }>();
  const venueId = venueParams?.venue_id || '';
  const location = useLocation();
  const history = useHistory();
  const shoppingCart = useShoppingCart();
  const client = useApolloClient();
  const findEntryFromItem = useEntryFinder();
  const { i18n } = useI18n();
  useVenueManager();
  const api = useBridgeApi();
  const shoppingCartApi = useShoppingCartApi();
  const venue = useSelector((state) => state.venue);
  const inventory = useSelector((state) => state.inventory);
  const cartId = useSelector((state) => state.cartId);
  const qrOrder = useSelector((state) => state.qrOrder);
  const loginModal = useSelector((state) => state.loginModal);
  const authToken = storageData.authToken;
  const [quantityItems, setQuantityItems] = useQuantity('1');
  const [activeScene, setActiveScene] = useState(SCENES.ITEM);
  const [upsalesStep, setUpsalesStep] = useState(0);
  const [upsalesQuantity, setUpsalesQuantity] = useState<UpsalesQuantity[]>([]);
  const isLoggedIn = !!authToken;
  const itemId = params?.item_id || '';

  useEffect(() => {
    if (!contentRef?.current?.scrollTo) {
      return;
    }
    contentRef.current.scrollTo({ top: 0 });
  }, [contentRef, activeScene]);

  const { data, loading, error: dataError } = useQuery<GetItem, GetItemVariables>(ITEM_QUERY, {
    variables: {
      id: itemId,
      companyId: config.COMPANY_ID,
    },
  });

  const entry = findEntryFromItem(itemId)!;

  const currentItem = useMemo(
    () =>
      client.readFragment<currentItemFragment>({
        id: itemId,
        fragment: gql`
          fragment currentItemFragment on Item {
            id
            image {
              id
              file {
                id
                url
              }
            }
            titleLang {
              id
              se
              en
              es
              no
            }
            descriptionLang {
              id
              se
              en
              es
              no
            }
          }
        `,
      }),
    [client, itemId, data, entry],
  );

  const lightEntry: LightEntry | null = useMemo(() => {
    if (entry && currentItem) {
      return {
        id: entry.id,
        price: entry.price,
        item: {
          id: currentItem.id,
          image: currentItem.image,
          titleLang: currentItem.titleLang,
          descriptionLang: currentItem.descriptionLang,
          defaultVariation: [],
          appliedVariations: [],
          modificationGroups: [],
          allergens: [],
        },
      };
    }
    return null;
  }, [entry, data, currentItem]);

  const onBack = () => {
    if (upsalesStep === 0) {
      return setActiveScene(SCENES.ITEM);
    }
    if (upsalesStep > 0 && upsalesStep < upsales.length) {
      return setUpsalesStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const itemScene = activeScene === SCENES.ITEM;
    if (changeOnBackFunc) {
      changeOnBackFunc(itemScene ? undefined : onBack);
    }
    if (changeShowOnScroll) {
      changeShowOnScroll(itemScene ? 50 : false);
    }
  }, [activeScene, upsalesStep]);

  const handleChangeQuantity = (quantity: string) => {
    api.vibrate('impactLight');
    setQuantityItems(quantity);
  };

  const handleItemButton = () => {
    // Allow "Anonymous Purchase" check.
    if (!config.ANONYMOUS_PURCHASE && !isLoggedIn && (!qrOrder || !storageData.qrOrder)) {
      // If restaurant doesn't allow anonymous purchases & the user is not logged in, show login modal (Unless it's table ordering).
      dispatch(setLoginModal(true));

      return;
    }

    const showUpsales = upsales.length > 0;

    if (showUpsales) {
      setActiveScene(SCENES.UPSALES);
    } else {
      handleAddToCart();
    }
  };

  const handleUpsalesButton = () => {
    handleAddToCart();
  };

  const itemData = data ?? lightEntry;
  data?.item.modificationGroups;

  const image = itemData?.item?.image || null;
  const titleLang = itemData?.item?.titleLang || null;
  const descriptionLang = itemData?.item?.descriptionLang || null;
  const appliedVariations = itemData?.item?.appliedVariations || [];
  const defaultVariations = itemData?.item?.defaultVariations || [];
  const modificationGroups = itemData?.item?.modificationGroups || [];

  const upsales = itemData?.item?.upsales || [];
  const allergens = itemData?.item?.allergens || [];

  // init upsales quantity
  useEffect(() => {
    if (!itemData?.item?.upsales) {
      return;
    }

    const updatedState = itemData.item.upsales.map((group) => {
      return {
        id: group.id,
        items: group.items.map((item) => ({
          id: item.id,
          quantity: 0,
          maxQuantity: getItemMaxQuantity(item.id, inventory, shoppingCart.items),
        })),
      };
    });

    setUpsalesQuantity(updatedState);
  }, [itemData, inventory, shoppingCart.items]);

  const onChangeUpsalesQuantity = useCallback(
    (groupId: string, id: string, quantity: string) => {
      const updatedState = upsalesQuantity.map((group) => {
        if (group.id === groupId) {
          const items = group.items.map((item) => {
            if (item.id === id) {
              return { ...item, quantity: parseInt(quantity, 10) };
            } else {
              return item;
            }
          });
          return { ...group, items };
        } else {
          return group;
        }
      });

      setUpsalesQuantity(updatedState);
    },
    [upsalesQuantity],
  );

  const [groups, onSelectVariation, selectedVariations, removedVariations] = useVariations({
    defaultVariations: defaultVariations.map((v) => v.id),
    appliedVariations: appliedVariations.map((av) => av.id),
    appliedModificationGroups: modificationGroups,
    initialSelection: appliedVariations.reduce((acc: string[], group) => {
      const preselectedVariationId = group.group.preselectedVariations?.[0]?.id;

      if (preselectedVariationId && !checkOutOfStock(preselectedVariationId, inventory, true)) {
        return [...acc, preselectedVariationId];
      }

      return acc;
    }, []),
  });

  const checkRequiredVariations = useCallback(() => {
    const disabled = groups.some((group) => !group.valid);

    if (!disabled) {
      return true;
    }

    const requiredVariationsGroup = groups.filter((g) => g.required);
    const notValidVariation = requiredVariationsGroup.find((g) => !g.valid);

    if (!notValidVariation) {
      return true;
    }

    return false;
  }, [groups]);

  const getTotal = useCallback(() => {
    if (!entry) {
      return 0;
    }

    const quantity = parseInt(quantityItems, 10) || 1;

    const variationsPrice = reduce(
      groups,
      (result, currentGroup) => {
        const selected = currentGroup.variations
          .filter((v) => v.selected)
          .filter((v) => defaultVariations.every((d) => d.id !== v.id));
        const sum = reduce(selected, (acc, val) => acc + val.price, 0);

        return result + sum;
      },
      0,
    );

    let total = entry.price;

    total += variationsPrice;

    return total * quantity;
  }, [quantityItems, data, entry, lightEntry, selectedVariations, findEntryFromItem]);

  const handleAddToCart = () => {
    if (!data || !shoppingCart) {
      return;
    }
    let categoryId: string;
    const categories = data.item.categories;

    if (categories[0]) {
      categoryId = categories[0].id;
    } else {
      categoryId = '';
    }

    const quantity = parseInt(quantityItems, 10);
    const totalPerItem = getTotal() / quantity;

    const selectedVariationsList = groups.reduce(
      (arr, group) => {
        group.variations.forEach((variation) => {
          if (variation.selected && !variation.isDefault) {
            arr.push({
              id: variation.id,
              amount: variation.price,
              title: variation.name,
              groupId: group.id,
            });
          }
        });
        return arr;
      },
      [] as Array<{
        id: string;
        amount: number;
        title: string;
        groupId: string;
      }>,
    );

    const removedVariationsList = removedVariations.map((v) => ({
      id: v.id,
      title: i18n(v.name),
    }));

    const items: Array<{
      title: string;
      itemId: string;
      entryId: string;
      variations: {
        id: string;
        amount: number;
        title: string;
        groupId: string;
      }[];
      removedVariations: string[];
      amount: number;
      disableMembershipDiscounts: boolean;
      discount: number;
      vatRate?: number | undefined;
      categoryId: string;
    }> = [];

    const itemToAdd = {
      title: i18n(data.item.titleLang),
      itemId,
      entryId: entry.id,
      variations: selectedVariationsList,
      removedVariations: removedVariationsList.map((r) => r.id),
      amount: totalPerItem,
      disableMembershipDiscounts: data.item.disableMembershipDiscount,
      discount: 0,
      vatRate: data.item.vatRate,
      categoryId,
    };

    if (quantity > 1) {
      for (let i = 0; i < quantity; i += 1) {
        items.push(itemToAdd);
      }
      logger.info('Items added to cart', {
        cartId,
        quantity,
        entryId: items[0].entryId,
        itemId: items[0].itemId,
        name: items[0].title,
      });
    } else if (quantity === 1) {
      items.push(itemToAdd);
      logger.info('Item added to cart', {
        cartId,
        entryId: entry.id,
        itemId,
        name: i18n(data.item.titleLang),
      });
    }

    const selectedUpsales = upsalesQuantity
      .flatMap((u) => u.items)
      .filter((item) => {
        return item.quantity > 0;
      });

    if (selectedUpsales.length > 0) {
      const allUpsaleItems = upsales.flatMap((u) => u.items);

      selectedUpsales.forEach((upsale) => {
        const upsaleEntry = findEntryFromItem(upsale.id);
        const item = allUpsaleItems.find((i) => i.id === upsale.id);

        if (!upsaleEntry || !item) {
          console.error('No entry for item');
          return;
        }

        let n = 0;

        while (n < upsale.quantity) {
          items.push({
            amount: upsaleEntry.price,
            disableMembershipDiscounts: item.disableMembershipDiscount,
            discount: 0,
            entryId: upsaleEntry.id,
            itemId: item.id,
            removedVariations: [],
            title: i18n(item.titleLang),
            variations: [],
            vatRate: item.vatRate,
            categoryId,
          });
          n++;
        }
      });
    }

    shoppingCartApi.addItems(items);

    api.vibrate('selection');

    if (venue && shoppingCart) {
      history.push(`/order/${venueId}${location.pathname.includes('menu') ? '/menu' : ''}`);
    }
  };

  const total = getTotal();

  useEffect(() => {
    if (changeTitle) {
      changeTitle(i18n(titleLang));
    }

    if (changeShowOnScroll) {
      changeShowOnScroll(activeScene === SCENES.ITEM ? 50 : false);
    }
  }, [changeTitle, changeShowOnScroll, titleLang, activeScene]);

  const allRequiredVariationsSelected = checkRequiredVariations();
  const itemMaxQuantity = getItemMaxQuantity(itemId, inventory, shoppingCart.items);

  return (
    <View
      activeScene={activeScene}
      itemImage={image?.file?.url}
      itemTitle={i18n(titleLang)}
      itemDescription={i18n(descriptionLang)}
      allRequiredVariationsSelected={allRequiredVariationsSelected}
      isLoggedIn={isLoggedIn}
      loading={loading}
      onItemButton={handleItemButton}
      quantity={quantityItems}
      itemMaxQuantity={itemMaxQuantity}
      onChangeQuantity={handleChangeQuantity}
      error={dataError}
      showLoginModal={loginModal}
      allergens={allergens}
      variationGroups={groups}
      onSelectVariationId={onSelectVariation}
      upsales={upsales}
      upsalesQuantity={upsalesQuantity}
      upsalesStep={upsalesStep}
      setUpsalesStep={setUpsalesStep}
      onUpsalesButton={handleUpsalesButton}
      onChangeUpsalesQuantity={onChangeUpsalesQuantity}
      total={total}
    />
  );
};

export default Item;
