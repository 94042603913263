import { useMemo } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { ModificationGroupFragment } from './__queries__';

export const useGetModificationGroups = (mgIDs: string[]): ModificationGroupFragment[] => {
  const client = useApolloClient();

  const modificationGroups = useMemo(() => {
    return mgIDs
      .map(
        (id) =>
          client.readFragment<ModificationGroupFragment>({
            id,
            fragment: gql`
              fragment ModificationGroupFragment on ModificationGroup {
                id
                externalName
                type
                required
                expandedByDefault
                preselectedVariations
                variations {
                  id
                  price
                  name
                  nameLang {
                    id
                    se
                    en
                    es
                    no
                  }

                  allergen

                  asItem {
                    id
                  }

                  image {
                    id
                    file {
                      id
                      url
                    }
                  }
                }
              }
            `,
          })!,
      )
      .filter(Boolean); // Item.modificationGroups array will not update when a modificationGroup is deleted. So we need to filter those out.
  }, [mgIDs, client]);

  return modificationGroups;
};
