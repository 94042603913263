import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import React from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import { useCurrency } from 'Hooks';

const Wrapper = styled.div`
  padding: 10px 24px 24px;
  border-bottom: 1px solid #e0e0e3;
  background-color: #fff;
`;

const VATText = styled.p`
  color: #616164;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  strong {
    color: #212124;
  }
`;

/**
 * VAT component
 */

const VAT: React.FC = () => {
  const shoppingCart = useShoppingCart();
  const { formatAmount } = useCurrency();
  const { i18n } = useI18n();

  return (
    <Wrapper>
      <VATText>
        <strong>{i18n('Cart.VAT.Total')}</strong>
        {formatAmount(shoppingCart.vat)}
      </VATText>
      <VATText translate="no">
        <strong>{i18n('Cart.VAT.TotalExcluding')}</strong>
        {formatAmount(shoppingCart.subtotal)}
      </VATText>
    </Wrapper>
  );
};

export default VAT;
