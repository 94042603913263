import React, { useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import DefaultView from './views/DefaultView';
import GridView from './views/GridView';
import { getAllMenuCategories, getAllMenuCategoriesVariables } from './__queries__';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import useEntryFinder from 'Hooks/useEntryFinder';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';
import { useStorage } from 'Components/Storage';
import { useSelector } from 'Hooks';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'Components/ConfigProvider';

export const MENU_CATEGORIES_QUERY = gql`
  query getAllMenuCategories($companyId: ID!) {
    allCategories(
      orderBy: order_DESC
      filter: {
        company: { id: $companyId }
        deleted: false
        OR: [{ hideInCustomerOrderingFlows: false }, { hideInCustomerOrderingFlows: null }]
      }
    ) {
      id
      parentId
      order
      name
      nameLang {
        id
        se
        en
        no
        es
      }
      image {
        id
        file {
          id
          url
        }
      }
      items(filter: { deleted: false }) {
        id
      }
    }
  }
`;

/**
 * MenuCategories scene
 */

const MenuCategories = () => {
  const config = useConfig();
  const shoppingCart = useShoppingCart();
  const { push } = useHistory();
  const [storage, _, storageLoading] = useStorage();
  const { tableId, qrOrder } = useSelector((state) => state);
  const venueId = useSelector((state) => state.venue?.id);
  const findEntryFromItem = useEntryFinder();
  const deviceSize = getDeviceSize();

  const { data } = useQuery<getAllMenuCategories, getAllMenuCategoriesVariables>(
    MENU_CATEGORIES_QUERY,
    {
      variables: {
        companyId: config.COMPANY_ID,
      },
    },
  );

  useEffect(() => {
    if (storageLoading) {
      return;
    }
    if (storage.serviceType) {
      return;
    }
    if (tableId) {
      push(`/?v=${venueId}&t=${tableId}`);
    } else if (!tableId && qrOrder) {
      push(`/?v=${venueId}`);
    } else {
      push(`/`);
    }
  }, [storageLoading, storage.serviceType]);

  // Only show categories that actually have items
  const categories = useMemo(() => {
    const cats = data?.allCategories || [];

    // Find all categories that have items
    const categoriesWithItems = cats.filter((cat) =>
      cat.items.some((item) => findEntryFromItem(item.id)),
    );

    // Find all categories that have subcategories that have items
    const topLevelCategoriesWithChildrenIds = categoriesWithItems
      .filter((c) => c.parentId)
      .map((c) => c.parentId);

    // Find all categories that have no items but have subcategories that have items
    const topLevelCategories = cats
      .filter((c) => c.items.filter((item) => findEntryFromItem(item.id)).length === 0)
      .filter((c) => topLevelCategoriesWithChildrenIds.includes(c.id))
      .concat(categoriesWithItems.filter((c) => !c.parentId));

    // Sort the result
    return topLevelCategories.sort((a, b) => (a.order > b.order ? -1 : 1));
  }, [data, shoppingCart.serviceType]);

  const isGrid = useMemo(() => {
    if (config.MENU_CATEGORIES_GRID) {
      return true;
    }

    return categories.some((category) => !category.image);
  }, [categories]);

  const isDesktop = deviceSize !== DEVICE_SIZES.SMALL && deviceSize !== DEVICE_SIZES.MEDIUM;

  if (isGrid || isDesktop) {
    return <GridView categories={categories} />;
  }

  return <DefaultView categories={categories} />;
};

export default MenuCategories;
