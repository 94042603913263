import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import {
  getVenueMenus,
  getVenueMenus_EAT_HERE_results,
  getVenueMenusVariables,
} from './__queries__';
import { DateTime } from 'luxon';
import { useDispatch, useLogger, useSelector } from 'Hooks';
import { useConfig } from 'Components/ConfigProvider';
import dayjs from 'dayjs';
import styled from 'styled-components';
import useI18n from 'i18n';
import { useStorage } from 'Components/Storage';
import { SERVICE_TYPE, useShoppingCart } from 'Components/ShoppingCartUniverse';

const TimeMismatchModal = styled.div`
  height: 95vh;
  width: 95vw;
  background-color: white;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
`;

export const findMenuForTime = (menus: getVenueMenus_EAT_HERE_results[], time: DateTime) => {
  const timeToCheckTowards = time ? time : DateTime.local(); // Get current local date and time
  const currentDayOfWeek = timeToCheckTowards.toFormat('cccc').toUpperCase(); // Get current day of the week in uppercase

  return [...menus].find((m) => {
    if (!m.availabilities || m.availabilities.length === 0) {
      return false;
    }

    // Iterate over each availability entry
    return m.availabilities.some((availability: any) => {
      const { dayOfWeek, timeRange } = availability;

      // Check if the current day matches the availability's day of availability
      if (!dayOfWeek.includes(currentDayOfWeek)) {
        return false;
      }

      // Check if the current time is within the availability's time range
      const startTime = DateTime.fromFormat(timeRange.start, 'HH:mm');
      let endTime;

      if (timeRange.end === '00:00') {
        // We cannot compare 00:00 directly since it is the start of the day, so we need to convert it to 23:59:59:999
        endTime = DateTime.fromFormat('23:59:59:999', 'HH:mm:ss:SSS');
      } else {
        endTime = DateTime.fromFormat(timeRange.end, 'HH:mm');
      }

      // Check if the current time is between start and end time
      return timeToCheckTowards >= startTime && timeToCheckTowards < endTime;
    });
  });
};

export const MENU_QUERY = gql`
  #  Seperated because service type does not exist in the menu on getAppliedMenus
  query getVenueMenus($companyId: String!, $venueId: String!) {
    TAKE_AWAY: getAppliedMenus(companyId: $companyId, venueId: $venueId, serviceType: TAKE_AWAY) {
      results {
        id
        availabilities {
          dayOfWeek
          timeRange {
            start
            end
          }
        }
        menu {
          id
          menuEntries {
            id

            price
            instant

            item {
              id
              vatRate
            }
          }
        }
      }
    }
    EAT_HERE: getAppliedMenus(companyId: $companyId, venueId: $venueId, serviceType: EAT_HERE) {
      results {
        id
        availabilities {
          dayOfWeek
          timeRange {
            start
            end
          }
        }
        menu {
          id
          menuEntries {
            id

            price
            instant

            item {
              id
              vatRate
            }
          }
        }
      }
    }
  }
`;

// Get's the server time to compare with the user's time
const TIME_QUERY = gql`
  query CurrentTime {
    currentTime
  }
`;

interface Props {
  serviceType: SERVICE_TYPE | null;
}

const MenuManager: React.FunctionComponent<Props> = ({ serviceType }) => {
  const logger = useLogger('menu-manager');
  const config = useConfig();
  const companyId = config.COMPANY_ID;
  const { i18n } = useI18n();
  const [storage, setStorage] = useStorage();
  const venueId = useSelector((state) => state.venue?.id ?? undefined);
  const serverTimeQuery = useQuery(TIME_QUERY, {
    fetchPolicy: 'network-only',
  });
  const cart = useShoppingCart();
  const [hasTimeMismatch, setHasTimeMismatch] = useState(false);
  const dispatch = useDispatch();
  const menuQuery = useQuery<getVenueMenus, getVenueMenusVariables>(MENU_QUERY, {
    variables: {
      companyId,
      venueId: venueId!,
    },
    skip: !companyId || !venueId,
    fetchPolicy: 'cache-and-network',
  });

  // This check and block is to prevent a user from changing their system time to bypass the menu availability (e.g. to order lunch prices during dinner)
  useEffect(() => {
    if (!serverTimeQuery.data) return;

    const now = dayjs();
    // Server time is in UTC
    const serverTime = serverTimeQuery.data.currentTime;

    // If there are more than 5 minutes mismatch between the server time and the user's time, show a warning
    // 5 minutes is a guard for faulty times in for example android phones (not user trying to bypass our platform)
    // The day js now includes the user timezone, so it compares correctly
    if (now.diff(serverTime, 'minute') > 5 || now.diff(serverTime, 'minute') < -5) {
      logger.error('Time mismatch', { userISOTime: now.toISOString(), serverTime });
      setHasTimeMismatch(true);
    } else {
      setHasTimeMismatch(false);
    }
  }, [serverTimeQuery.data]);

  const takeAwayMenus = useMemo(() => menuQuery.data?.TAKE_AWAY.results ?? [], [menuQuery.data]);
  const eatHereMenus = useMemo(() => menuQuery.data?.EAT_HERE.results ?? [], [menuQuery.data]);
  const allMenus = useMemo(() => [...takeAwayMenus, ...eatHereMenus], [
    takeAwayMenus,
    eatHereMenus,
  ]);

  // Update state if there are time controlled menus (used in various places to show/hide elements)
  useEffect(() => {
    if (!allMenus || !allMenus.length) {
      return;
    }
    if (
      allMenus.some((menu) => {
        return menu.availabilities?.length > 0;
      })
    ) {
      setStorage({
        hasTimeControlledMenus: true,
      });
    } else {
      setStorage({
        hasTimeControlledMenus: false,
      });
    }
  }, [allMenus]);

  useEffect(() => {
    if (!allMenus.length) {
      return;
    }

    // If there is a time mismatch we don't set the menu at all
    if (hasTimeMismatch) {
      dispatch({
        type: 'SET_MENU',
        menu: false,
      });
      return;
    }

    const serviceTypeMenus = serviceType === 'TAKE_AWAY' ? takeAwayMenus : eatHereMenus;

    // If there are no menus for the service type, set the menu to false
    if (serviceTypeMenus.length === 0) {
      dispatch({
        type: 'SET_MENU',
        menu: false,
      });
      return;
    }

    const hasAvailability = serviceTypeMenus.some((m) => m.availabilities?.length > 0);

    // If there is only one menu for the service type, set the menu to that menu
    if (!hasAvailability) {
      dispatch({
        type: 'SET_MENU',
        menu: serviceTypeMenus[0].menu ?? false,
      });

      return;
    }

    // If there are multiple menus for the service type check the availability of each menu and choose the correct one based on current time
    const pickuptime =
      cart.pickupTime && cart.pickupTime !== 'ASAP'
        ? DateTime.fromISO(cart.pickupTime)
        : DateTime.local();
    const menuToUse = findMenuForTime(serviceTypeMenus, pickuptime);
    const foundDefaultMenu = serviceTypeMenus.find((menu) => menu.availabilities.length === 0);

    if (!menuToUse && foundDefaultMenu) {
      logger.info('Setting default menu', foundDefaultMenu);
      dispatch({
        type: 'SET_MENU',
        menu: foundDefaultMenu.menu,
      });
      return;
    }

    dispatch({
      type: 'SET_MENU',
      menu: menuToUse?.menu ?? false,
    });
  }, [serviceType, allMenus, hasTimeMismatch, cart.pickupTime]);

  if (hasTimeMismatch) {
    return (
      <TimeMismatchModal>
        <h2>{i18n('Cart.TimeMismatch.ErrorTitle')}</h2>
        <p>{i18n('Cart.TimeMismatch.Error')}</p>
      </TimeMismatchModal>
    );
  }

  return null;
};

export default React.memo(MenuManager);
