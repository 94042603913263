import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { AppliedVariationFragment, DefaultVariationFragment } from './__queries__';

export const useGetVariations = (
  avIDs: string[],
  defIDs: string[],
): [AppliedVariationFragment[], DefaultVariationFragment[]] => {
  const client = useApolloClient();

  const [appliedVariations, defaultVariations] = useMemo(() => {
    const defaults = defIDs.map(
      (id) =>
        client.readFragment<DefaultVariationFragment>({
          id,
          fragment: gql`
            fragment DefaultVariationFragment on Variation {
              id
              price
              name
              nameLang {
                id
                se
                en
                es
                no
              }

              # This is not actually used, but makes both variation
              # fragments have the same schema which makes life easier
              asItem {
                id
              }

              image {
                id
                file {
                  id
                  url
                }
              }
            }
          `,
        })!,
    );

    const applied = avIDs.map(
      (id) =>
        client.readFragment<AppliedVariationFragment>({
          id,
          fragment: gql`
            fragment AppliedVariationFragment on AppliedVariation {
              id
              required
              expandedByDefault

              group {
                id
                type
                required

                title {
                  id
                  se
                  en
                  es
                  no
                }
                preselectedVariations {
                  id
                }
                variations {
                  id
                  price
                  name
                  asItem {
                    id
                  }

                  nameLang {
                    id
                    se
                    en
                    es
                    no
                  }

                  image {
                    id
                    file {
                      id
                      url
                    }
                  }
                }
              }
            }
          `,
        })!,
    );

    return [applied, defaults];
  }, [avIDs, defIDs, client]);

  return [appliedVariations, defaultVariations];
};
