import { useSelector } from 'Hooks';
import { CURRENCY } from 'Types'; // Import should be from globalTypes file but react does not allow imports from outside of /src **TODO** Find a way to import globalTypes

// {new-country-onboarding} Add all new currencies here
const currencyToSymbolMap: Record<CURRENCY | string, string> = {
  [CURRENCY.DKK]: 'kr',
  [CURRENCY.EUR]: '€',
  [CURRENCY.NOK]: 'nkr',
  [CURRENCY.USD]: '$',
  [CURRENCY.SEK]: 'kr',
  [CURRENCY.GBP]: '£',
  TRY: '₺',
};

// {new-country-onboarding} Add all new currencies here
const currencyToLocaleMap: Record<CURRENCY | string, string> = {
  [CURRENCY.DKK]: 'da-DK',
  [CURRENCY.EUR]: 'nl-NL',
  [CURRENCY.NOK]: 'nb-NO',
  [CURRENCY.USD]: 'en-US',
  [CURRENCY.SEK]: 'sv-SE',
  [CURRENCY.GBP]: 'en-GB',
  TRY: 'tr-TR',
};

// {new-country-onboarding} Add all new currencies here
const nonLegacyCurrencies: Record<string, string> = {
  TR: 'TRY',
};

const twoDecimalCurrencies = [CURRENCY.GBP, CURRENCY.EUR, 'TRY'];

export const useCurrency = () => {
  const currency = useSelector((state) => state.companyCurrency) || CURRENCY.SEK;
  const country = useSelector((state) => state.country);

  const currencyType =
    country && nonLegacyCurrencies[country] ? nonLegacyCurrencies[country] : currency;

  const showTwoDecimals = twoDecimalCurrencies.includes(currencyType);

  const formatAmount = (amount: number, divide = true): string => {
    // Guard, if the currency type is misstyped/is not supported
    if (!currencyType) {
      return (divide ? amount / 100 : amount).toString();
    }

    const formatter = new Intl.NumberFormat(currencyToLocaleMap[currencyType], {
      style: 'currency',
      currency: currencyType,
      minimumFractionDigits: showTwoDecimals ? 2 : 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(divide ? amount / 100 : amount);
  };

  const symbol = (currencyCode?: CURRENCY | string) => {
    return currencyToSymbolMap[currencyCode || currencyType];
  };

  return {
    formatAmount,
    symbol,
    code: currencyType,
  };
};
