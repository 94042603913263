import React from 'react';
import styled from 'styled-components';
import { ApolloError } from '@apollo/client/errors';

import useI18n from '../../../i18n';
import BottomBar from '../Components/BottomBar';
import { CombinedVariationGroup } from '../../../Components/Variations';
import Group, { ExpandedGroup, GROUP_TYPE } from '../Components/Group';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import { DEVICE_SIZES, getDeviceSize } from '../../../utils/deviceSize';
import logo from '../../../Assets/Images/logo.svg';
import { ALLERGEN } from '../../../../__queries__';
import { useSelector } from 'Hooks';

const bottomBarHeight = 138;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: fade-in 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Inner = styled.div`
  display: flex;
  flex: 1;
  padding: 412px 0 ${bottomBarHeight}px 0;
  position: relative;
  overflow-y: clip;
`;

const Title = styled.h2`
  color: rgb(33, 33, 36);
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 16px 0;
`;

const Description = styled.p`
  color: rgb(33, 33, 36);
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin: 0 0 32px 0;
`;

const Content = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 24px 24px 0 0;
  padding: 24px;
  flex: 1;
  position: relative;
`;

const ContentInner = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const ContentBottom = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
`;

const ItemImage = styled.div<{ itemImage: boolean; loading: boolean }>`
  position: fixed;
  width: 100%;
  height: 432px;
  top: 0;
  transition: height 0.4s ease-in-out;
  background: ${({ itemImage, loading }) => {
    if (loading || itemImage) {
      return 'none';
    } else {
      return `url(${logo}) no-repeat center`;
    }
  }};
  background-color: ${({ theme }) => theme.main};
  background-size: 150px;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

type Props = {
  /**
   * allergens
   */
  allergens: ALLERGEN[];
  /**
   * variationGroups
   */
  variationGroups: CombinedVariationGroup[];
  /**
   * itemImage
   */
  itemImage: string | undefined;
  /**
   * itemTitle
   */
  itemTitle: string;
  /**
   * itemDescription
   */
  itemDescription: string;
  /**
   * allRequiredVariationsSelected
   */
  allRequiredVariationsSelected: boolean;
  /**
   * onSelectVariationId
   */
  onSelectVariationId: (id: string) => void;
  /**
   * onItemButton
   */
  onItemButton: () => void;
  /**
   * total
   */
  total: number;
  /**
   * quantity
   */
  quantity: string;
  /**
   * itemMaxQuantity
   */
  itemMaxQuantity?: number;
  /**
   * onChangeQuantity
   */
  onChangeQuantity: (quantity: string) => void;
  /**
   * showLoginModal
   */
  showLoginModal: boolean;
  /**
   * error
   */
  error: ApolloError | undefined;
  /**
   * isLoggedIn
   */
  isLoggedIn: boolean;
  /**
   * loading
   */
  loading: boolean;
};

interface GroupWithId extends ExpandedGroup {
  id: string;
  required: boolean;
}

/**
 * Item scene
 */
const Item = ({
  allergens,
  variationGroups,
  itemImage,
  itemTitle,
  itemDescription,
  allRequiredVariationsSelected,
  onSelectVariationId,
  onItemButton,
  total,
  quantity,
  itemMaxQuantity,
  onChangeQuantity,
  showLoginModal,
  error,
  isLoggedIn,
  loading,
}: Props) => {
  const { i18n } = useI18n();
  const enableModifications = useSelector((state) => state.enableModifications);

  const shoppingCart = useShoppingCart();
  const deviceSize = getDeviceSize();

  const groups: GroupWithId[] = variationGroups.map((g) => ({
    id: g.id,
    title: i18n(g.title),
    type: g.type === 'MULTIPLE' ? GROUP_TYPE.MULTIPLE : GROUP_TYPE.SINGLE,
    description: i18n('Item.ChooseOption'),
    expandedByDefault: g.expandedByDefault,
    valid: g.valid,
    collapsible: true,
    onSelect: (id: string) => {
      onSelectVariationId(id);
    },
    items: g.variations.map((gv) => ({
      id: gv.id,
      name: gv.name,
      price: gv.price,
      imageUrl: gv?.imageUrl,
      selected: gv.selected,
      isOutOfStock: gv.isOutOfStock,
    })),
    required: g.required,
    moreText: i18n('General.More'),
    preselectedVariations: g.preselectedVariations,
  }));

  const allergensGroup: ExpandedGroup = {
    title: i18n('Item.Allergens'),
    type: null,
    description: '',
    expandedByDefault: false,
    collapsible: true,
    onSelect: null,
    items: allergens.map((allergen) => ({
      name: i18n(`Item.Allergen.${allergen}`),
    })),
    preselectedVariations: [], // This is here for typescript and the field is only for modifications 2.0
  };

  const headImage =
    deviceSize === DEVICE_SIZES.SMALL
      ? getImageUrlWithSize(itemImage, window.innerWidth * 2, 864)
      : itemImage;

  return (
    <Wrapper>
      <ItemImage itemImage={Boolean(itemImage)} loading={loading}>
        {headImage && <Image src={headImage} />}
      </ItemImage>
      <Inner>
        <Content>
          <ContentInner>
            <Title>{itemTitle}</Title>
            {itemDescription && <Description>{itemDescription}</Description>}
            {allergens.length > 0 && (
              <Group
                type={allergensGroup.type}
                title={i18n('Item.Allergens')}
                description={''}
                items={allergensGroup.items}
                expandedByDefault={allergensGroup.expandedByDefault}
                collapsible={allergensGroup.collapsible}
                onSelect={allergensGroup.onSelect}
                preselectedVariations={allergensGroup.preselectedVariations}
              />
            )}
            {groups.map((g) => (
              <Group
                type={g.type}
                title={i18n(g.title)}
                description={i18n('Item.ChooseOption')}
                key={g.id}
                items={g.items}
                expandedByDefault={
                  enableModifications
                    ? g.expandedByDefault || (g.required && g.preselectedVariations.length === 0)
                    : g.required || (!g.required && g.expandedByDefault)
                }
                collapsible={
                  enableModifications
                    ? !g.expandedByDefault || !(g.required && g.preselectedVariations.length === 0)
                    : !g.required
                }
                onSelect={g.onSelect}
                preselectedVariations={g.preselectedVariations}
              />
            ))}
            <ContentBottom />
          </ContentInner>
        </Content>
      </Inner>
      {(shoppingCart || !showLoginModal) && (
        <BottomBar
          loading={loading}
          error={error}
          total={total}
          setQuantity={onChangeQuantity}
          quantity={quantity}
          onPress={onItemButton}
          allRequiredVariationsSelected={allRequiredVariationsSelected}
          isLoggedIn={isLoggedIn}
          itemMaxQuantity={itemMaxQuantity}
        />
      )}
    </Wrapper>
  );
};

export default Item;
