import { gql } from '@apollo/client';

export const ITEM_QUERY = gql`
  query GetItem($id: ID!, $companyId: ID!) {
    company: Company(id: $companyId) {
      id
      modificationGroups {
        id
        externalName
        type
        required
        expandedByDefault
        preselectedVariations
        variations {
          id
          price
          name
          nameLang {
            id
            se
            en
            es
            no
          }

          allergen

          asItem {
            id
          }

          image {
            id
            file {
              id
              url
            }
          }
        }
      }
    }
    item: Item(id: $id) {
      id
      allergens
      ageRestricted
      title
      vatRate
      disableMembershipDiscount
      categories {
        id
      }
      titleLang {
        id
        se
        en
        es
        no
      }
      description
      descriptionLang {
        id
        se
        en
        es
        no
      }
      defaultVariations {
        id
        price
        name
        nameLang {
          id
          se
          en
          es
          no
        }

        # This is not actually used, but makes both variation
        # fragments have the same schema which makes life easier
        asItem {
          id
        }

        image {
          id
          file {
            id
            url
          }
        }
      }
      modificationGroups
      appliedVariations {
        id
        default
        required
        variation {
          id
          name
          price
        }
        expandedByDefault

        group {
          id
          type
          title {
            id
            en
            se
            es
            no
          }
          required
          preselectedVariations {
            id
          }
          variations {
            id
            name
            nameLang {
              id
              en
              se
              es
              no
            }
            price
            absolutePrice
            asItem {
              id
            }
            image {
              id
              file {
                id
                url
              }
            }
          }
        }
      }
      sizes {
        id
        type
        variations {
          id
          price
          absolutePrice
          name
        }
      }
      image {
        id
        file {
          id
          url
        }
      }
      upsales {
        id
        enableMultiSelect
        name {
          id
          se
          en
        }
        items {
          id
          disableMembershipDiscount
          vatRate
          titleLang {
            id
            se
            en
            es
            no
          }
          defaultPrice
          image {
            id
            file {
              id
              url
            }
          }
          categories {
            id
            name
            deleted
          }
        }
      }
    }
  }
`;
