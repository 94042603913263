import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { CSSProperties } from 'styled-components';

const Wrapper = styled.div<{
  position: 'center' | 'flex-start' | 'flex-end';
  animateOut: boolean;
  noPadding?: boolean;
  zIndex?: number;
}>`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex || 99};
  padding: ${({ noPadding }) => (noPadding ? '0' : '85px 16px')};

  @media screen and (max-width: 400px) {
    padding: 24px 16px;
  }

  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  justify-content: ${({ position }) => position};
  align-items: center;
  box-sizing: border-box;
  animation: ${({ animateOut }) =>
    animateOut ? 'fade-out 0.1s linear forwards' : 'fade-in 0.1s linear forwards'};

  div,
  button {
    z-index: 1;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

interface Props {
  isShowing: boolean;
  hide?: () => void;
  backdropClose?: boolean;
  position?: 'center' | 'flex-start';
  noPadding?: boolean;
  zIndex?: number;
  style?: CSSProperties;
}

const Modal: React.FC<Props> = ({
  isShowing,
  hide,
  backdropClose = true,
  position,
  children,
  noPadding,
  zIndex,
  style,
}) => {
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position) return;

    if (ref.current) {
      const input = ref.current.querySelector('textarea') || ref.current.querySelector('input');
      const isFocused = document.activeElement === input;

      if (isFocused) {
        setKeyboardIsOpen(true);
      } else {
        setKeyboardIsOpen(false);
      }

      if (input) {
        input.addEventListener('blur', () => {
          setKeyboardIsOpen(false);
        });

        input.addEventListener('focus', () => {
          setKeyboardIsOpen(true);
        });
      }
    }
  }, [ref, children]);

  useEffect(() => {
    if (!keyboardIsOpen) {
      return;
    }

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [keyboardIsOpen]);

  useEffect(() => {
    if (!isShowing) {
      setKeyboardIsOpen(false);
    }
  }, [isShowing]);

  const handleClose = () => {
    setAnimateOut(true);

    setTimeout(() => {
      setAnimateOut(false);
      hide && hide();
    }, 200);
  };

  return isShowing
    ? createPortal(
        <Wrapper
          position={position ? position : 'center'}
          ref={ref}
          animateOut={animateOut}
          noPadding={noPadding}
          zIndex={zIndex}
          style={style}>
          <Backdrop
            onClick={(e) => {
              e.stopPropagation();
              if (backdropClose) {
                handleClose();
              }
            }}
            onTouchStart={backdropClose ? handleClose : undefined}
          />
          {children}
        </Wrapper>,
        document.body,
      )
    : null;
};

export default Modal;
