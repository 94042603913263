import React from 'react';
import { gql, useQuery } from '@apollo/client';
import View from './view';
import { getHistoryListing, getHistoryListing_user_orders } from './__queries__';

export const HISTORY_LISTING_QUERY = gql`
  query getHistoryListing {
    user {
      id
      orders(
        orderBy: createdAt_DESC
        first: 30
        filter: { isTableOrder: false, status_in: [COMPLETED, REJECTED] }
      ) {
        id
        createdAt
        status
        venue {
          id
          name
        }
        fees {
          id
          price {
            id
            amount
          }
        }
        items {
          id
          price {
            id
            amount
            discount
          }
        }
      }
    }
  }
`;

export type Order = {
  id: string;
  title: string;
  date: string;
  amount: number;
  status: string;
};

const POLLING_INTERVAL = 5000;

/**
 * HistoryListing scene
 */
const HistoryListing = () => {
  const { data } = useQuery<getHistoryListing>(HISTORY_LISTING_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: POLLING_INTERVAL,
  });

  const userOrders: getHistoryListing_user_orders[] = data?.user?.orders || [];

  const orders = userOrders.reduce((acc: Order[], order) => {
    if (!order.items.length) {
      return acc;
    }

    const costOfItems = order.items.reduce((sum, obj) => {
      if (!obj.price) {
        return sum;
      }

      return sum + (obj.price.amount - obj.price.discount);
    }, 0);

    const costOfFees = (order.fees || []).reduce((sum, obj) => {
      return sum + obj.price.amount;
    }, 0);

    return [
      ...acc,
      {
        id: order.id,
        title: order.venue.name,
        date: order.createdAt,
        amount: costOfItems + costOfFees,
        status: order.status,
      },
    ];
  }, []);

  return <View orders={orders} />;
};

export default HistoryListing;
