import React, { useState, useEffect, useContext, useRef } from 'react';
import { UniverseBuilder } from '@baemingo/shopping-cart-events';
import { useSelector, useDispatch, useLogger, useCurrency } from 'Hooks';
import { v4 as uuid } from 'uuid';
import ReactGA from 'react-ga4';
import * as actions from 'actions';
import { gql, useQuery } from '@apollo/client';
import { AvailableOffer, PromoCode } from '@baemingo/shopping-cart-events/dist/types';
import { useConfig } from 'Components/ConfigProvider';

interface ActivatedPromoCode extends PromoCode {
  venues: string[];
}

export type SERVICE_TYPE = 'TAKE_AWAY' | 'EAT_HERE' | 'DELIVERY';

/**
 * Create a context with an empty universe. This context acts as a
 * state that we can access from child components. We use this in app.tsx
 */
const Context = React.createContext(new UniverseBuilder().serialize());

export const ShoppingCartUniverseProvider: React.FunctionComponent = ({ children }) => {
  const logger = useLogger('shopping-cart-universe-provider');
  // A local universe
  const universe = useRef(new UniverseBuilder());

  // Get events from state. Events are added via UI actions or synced from backend
  const events = useSelector((state) => state.shoppingCartEvents);

  const cartId = useSelector((state) => state.cartId);

  // We save the serialized universe in state to trigger re-renders
  const [state, setState] = useState(universe.current.serialize());

  useEffect(() => {
    universe.current = new UniverseBuilder();

    events.forEach((event) => {
      // Basic sanity check
      if (!event.venueId) {
        logger.warn('Event without venueId: ' + event.type);
      }

      try {
        universe.current.addEvent(event);
      } catch (e) {
        console.error(e);
      }
    });

    setState(universe.current.serialize());
  }, [events]);

  useEffect(() => {
    if (!cartId) {
      universe.current = new UniverseBuilder();
    }
  }, [cartId]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useShoppingCart = () => {
  return useContext(Context);
};

export const ITEM_VATRATES = gql`
  query GetItemVatRates($companyId: ID!) {
    items: allItems(filter: { company: { id: $companyId } }) {
      id
      vatRate
    }
  }
`;
export const useShoppingCartApi = () => {
  const config = useConfig();
  const dispatch = useDispatch();
  const logger = useLogger('shopping-cart-api');
  const cartId = useSelector((state) => state.cartId!);
  const user = useSelector((state) => state.user);
  const membershipRate = useSelector((state) => state.userMembership?.rate ?? 0);
  const venueId = useSelector((state) => state.venue?.id ?? '');
  const { formatAmount } = useCurrency();

  const itemsQuery = useQuery(ITEM_VATRATES, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const items = itemsQuery.data?.items ?? [];

  const generateEventMeta = () => ({
    createdAt: new Date().toISOString(),
    eventId: uuid(),
    sentAt: null,
    cartId,
    venueId,
  });

  const API = {
    createCart: (
      venueId: string,
      serviceType: SERVICE_TYPE,
      deliveryTargetId: string | null,
      markAsPayLaterOrder: boolean,
      pickupTime?: string | null,
    ) => {
      const id = uuid();
      dispatch(actions.setCartId(id));

      setTimeout(() => {
        const eventMeta = generateEventMeta();
        dispatch(
          actions.addShoppingCartEvent({
            type: 'CREATE_CART',
            deliveryTargetId,
            serviceType,
            userId: user?.id,
            ...eventMeta,
            cartId: id,
            venueId,
          }),
        );

        // We need pickup time here to not have to worry about if the cart has been created or not
        if (pickupTime) {
          const pickupEventMeta = generateEventMeta();
          dispatch(
            actions.addShoppingCartEvent({
              type: 'SET_PICKUP_TIME',
              time: pickupTime,
              ...pickupEventMeta,
              cartId: id,
              venueId,
            }),
          );
        }

        if (markAsPayLaterOrder) {
          dispatch(
            actions.addShoppingCartEvent({
              type: 'MARK_AS_PAY_LATER_ORDER',
              ...generateEventMeta(),
              cartId: id,
              venueId,
            }),
          );
        }

        logger.info('Sending CREATE_CART event', {
          type: 'CREATE_CART',
          deliveryTargetId,
          serviceType,
          userId: user?.id,
          cartId: id,
          venueId,
          eventId: eventMeta.eventId,
        });
      }, 10);

      // Trigger GA event that a cart is created.
      ReactGA.event({
        category: 'Cart',
        action: 'cart_created',
      });

      return id;
    },
    connectToCheque: (chequeId: string) => {
      dispatch(
        actions.addShoppingCartEvent({
          type: 'CONNECT_TO_CHEQUE',
          ...generateEventMeta(),
          id: chequeId,
        }),
      );
    },
    addItem: (
      title: string,
      itemId: string,
      entryId: string,
      variations: Array<{
        id: string;
        amount: number;
        title: string;
        groupId: string;
      }>,
      removedVariations: string[],
      amount: number,
      disableMembershipDiscounts: boolean,
      categoryId: string,
      vatRate?: number,
      id?: string,
      discount?: number,
    ) => {
      const findVatRate = vatRate || items.find((i: any) => i.id === itemId)?.vatRate;

      if (!findVatRate) {
        logger.error('Missing vat rate, ignoring add');
        return;
      }
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'ADD_ITEM',
          item: {
            id: id || uuid(),
            disableMembershipDiscounts,
            discount: discount ?? 0,
            fraction: 1,
            itemId,
            entryId,
            vatRate: vatRate ?? findVatRate,
            message: null,
            amount,
            variations,
            removedVariations,
            title,
            categoryId,
          },
          ...eventMeta,
        }),
      );
      logger.info(`Adding item to cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'ADD_ITEM',
      });
    },
    addItems: (
      itemsArray: Array<{
        title: string;
        itemId: string;
        entryId: string;
        variations: Array<{
          id: string;
          amount: number;
          title: string;
          groupId: string;
        }>;
        removedVariations: string[];
        amount: number;
        disableMembershipDiscounts: boolean;
        discount: number;
        vatRate?: number;
        categoryId: string;
      }>,
    ) => {
      const vatRate = items.find((i: any) => i.id === itemsArray[0].itemId)?.vatRate;
      if (!vatRate) {
        logger.error('Missing vat rate, ignoring add');
        return;
      }
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'ADD_ITEMS',
          items: itemsArray.map((item) => ({
            ...item,
            id: uuid(),
            fraction: 1,
            vatRate: vatRate,
            message: null,
          })),
          ...eventMeta,
        }),
      );
      logger.info(`Adding items to cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'ADD_ITEMS',
      });
    },
    clearCart: () => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'CLEAR_CART',
          ...eventMeta,
        }),
      );
      logger.info(`Clearing cart: ${cartId}`, { eventId: eventMeta.eventId, type: 'CLEAR_CART' });
    },
    removeItem: (id: string) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'REMOVE_ITEM',
          id,
          ...eventMeta,
        }),
      );
      logger.info(`Removing item: ${id} from cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'REMOVE_ITEM',
      });
    },
    setComment: (comment: string) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'SET_COMMENT',
          comment,
          ...eventMeta,
        }),
      );
      logger.info(`Setting comment on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'SET_COMMENT',
      });
    },
    setPickupTime: (time: string) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'SET_PICKUP_TIME',
          time,
          ...eventMeta,
        }),
      );
      logger.info(`Setting pickup time on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'SET_PICKUP_TIME',
        time: time,
      });
    },
    setOrderOffer: (id: string, title: string, discountRate: number, discountType: any) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'SET_ORDER_OFFER',
          id,
          title,
          discountRate,
          discountType,
          ...eventMeta,
        }),
      );
      logger.info(`Setting Order Offer: ${id} on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'SET_ORDER_OFFER',
      });
    },
    clearOrderOffer: () => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'CLEAR_ORDER_OFFER',
          ...eventMeta,
        }),
      );
      logger.info(`Clearing Order Offer on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'CLEAR_ORDER_OFFER',
      });
    },
    setMembershipDiscount: (discountRate: number) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'SET_MEMBERSHIP_DISCOUNT',
          discountRate,
          ...eventMeta,
        }),
      );
      logger.info(`Setting membership discount on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'SET_MEMBERSHIP_DISCOUNT',
      });
    },
    markAsPayLaterOrder: (id: string) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'MARK_AS_PAY_LATER_ORDER',
          ...eventMeta,
          cartId: id,
        }),
      );

      logger.info(`Marking cart as pay later: ${id}`, {
        eventId: eventMeta.eventId,
        type: 'MARK_AS_PAY_LATER_ORDER',
      });
    },
    updateCartUser: (id: string) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'UPDATE_USER',
          id,
          ...eventMeta,
        }),
      );
      logger.info(`Updating user: ${id} on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'UPDATE_USER',
      });
    },
    addAvailableOffer: (offer: AvailableOffer) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'ADD_OFFER',
          ...offer,
          ...eventMeta,
        }),
      );
      logger.info(`Adding offer: ${offer.id} to cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'ADD_OFFER',
      });
    },
    addClientSideInvoicePayment: (amount: number, tip: number) => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'ADD_CLIENT_SIDE_PAYMENT',
          ...eventMeta,
          id: uuid(),
          tip,
          paymentType: 'INVOICE',
          transactionId: '',
          amount,
          payload: '',
        }),
      );
      logger.info(`Creating invoice payment. Amount: ${formatAmount(amount)}`, {
        eventId: eventMeta.eventId,
        type: 'ADD_CLIENT_SIDE_PAYMENT',
        cartId,
      });
    },
    addClientSidePayment: () => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'ADD_CLIENT_SIDE_PAYMENT',
          ...eventMeta,
          id: uuid(),
          tip: 0,
          paymentType: 'ADYEN',
          transactionId: '',
          amount: 0,
          payload: '',
        }),
      );
      logger.info(`Adding client side payment to cart: ${cartId}`);
    },
    setPromoCode: (promocode: ActivatedPromoCode) => {
      const { id, code, discountRate, discountType, title, venues, categoryIds } = promocode;
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'SET_PROMO_CODE',
          categoryIds,
          id,
          code,
          discountRate,
          discountType,
          title,
          venues,
          offerType: 'ORDER_DISCOUNT',
          ...eventMeta,
        }),
      );
      logger.info(`Setting promo code: ${code} on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'SET_PROMO_CODE',
      });
    },
    clearPromoCode: () => {
      const eventMeta = generateEventMeta();
      dispatch(
        actions.addShoppingCartEvent({
          type: 'CLEAR_PROMO_CODE',
          ...eventMeta,
        }),
      );
      logger.info(`Clearing promo code on cart: ${cartId}`, {
        eventId: eventMeta.eventId,
        type: 'CLEAR_PROMO_CODE',
      });
    },
  };

  return API;
};
