import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../Components/CheckIcon';
import RadioIcon from '../../../Components/RadioIcon';
import { useCurrency, useBridgeApi } from 'Hooks';
import { ExpandedGroupItem, GROUP_TYPE } from './Group';
import getImageUrlWithSize from '../../../utils/getImageUrlWithSize';
import useI18n from 'i18n';
import Quantity from './Quantity';

const Wrapper = styled.div<{ cursor: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: solid 1px rgb(224, 224, 227);
  min-height: 75px;
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};

  :last-child {
    border-bottom: none;
  }
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 5px 0 0;
`;

const ItemName = styled.strong<{ isOutOfStock?: boolean }>`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  ${({ isOutOfStock }) =>
    isOutOfStock &&
    `
    opacity: 0.5;
    `}
`;

const Price = styled.span<{ isOutOfStock?: boolean }>`
  color: rgb(97, 97, 100);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 20px;

  ${({ isOutOfStock }) =>
    isOutOfStock &&
    `
    opacity: 0.5;
    `}
`;

const Image = styled.div<{ image: any; isOutOfStock?: boolean }>`
  position: relative;
  border-radius: 2px;
  height: 64px;
  width: 96px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
`;

const ImageText = styled.span`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0 8px;

  color: #fff;
  background: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
`;

export interface Props extends ExpandedGroupItem {
  /**
   * onSelect
   */
  onSelect: ((id: string) => void) | null;
  /**
   * type
   */
  type: GROUP_TYPE | null;
  /**
   * onChangeQuantity
   */
  onChangeQuantity: (quantity: string) => void;
  /**
   * enableMultiSelect
   */
  enableMultiSelect?: boolean;
  /**
   * selectionDisabled
   */
  selectionDisabled?: boolean;
}

/**
 * GroupItemWithImage component
 */
const GroupItemWithImage = ({
  id,
  name,
  price,
  onSelect,
  type,
  selected,
  imageUrl,
  isOutOfStock,
  quantity,
  maxQuantity,
  enableMultiSelect,
  selectionDisabled,
  onChangeQuantity,
}: Props) => {
  const api = useBridgeApi();
  const { i18n } = useI18n();
  const { formatAmount } = useCurrency();
  const quantityEnable = quantity !== undefined;

  return (
    <Wrapper
      cursor={Boolean(onSelect)}
      onClick={() => {
        if (!onSelect || !id || isOutOfStock || quantityEnable) {
          return;
        }

        api.vibrate('impactLight');
        onSelect(id);
      }}>
      {(() => {
        if (quantityEnable) {
          return null;
        }
        if (type === GROUP_TYPE.MULTIPLE) {
          return <CheckIcon active={selected} />;
        } else if (type === GROUP_TYPE.SINGLE) {
          return <RadioIcon active={selected} />;
        }
        return null;
      })()}
      <TextInfo>
        <ItemName isOutOfStock={isOutOfStock}>{name}</ItemName>
        {!!price && <Price isOutOfStock={isOutOfStock}>+{formatAmount(price)}</Price>}
      </TextInfo>
      <Image image={getImageUrlWithSize(imageUrl, 192, 128)} isOutOfStock={isOutOfStock}>
        {isOutOfStock && <ImageText>{i18n('General.OutOfStock')}</ImageText>}
      </Image>
      {quantityEnable && (
        <Quantity
          setQuantity={onChangeQuantity}
          quantity={quantity!.toString()}
          small
          enableMultiSelect={enableMultiSelect}
          selectionDisabled={selectionDisabled}
          minValue={0}
          maxValue={maxQuantity}
          disabled={isOutOfStock}
        />
      )}
    </Wrapper>
  );
};

export default GroupItemWithImage;
