import { useState, useMemo, useEffect } from 'react';
import difference from 'lodash/difference';
import flatMap from 'lodash/flatMap';

import { useSelector } from '../../Hooks';
import { checkOutOfStock } from '../../utils/inventory';
import useI18n from '../../i18n';
import { useGetVariations } from './api/useGetVariations';
import { useGetModificationGroups } from './api/useGetModificationGroups';
import {
  AppliedVariationFragment_group,
  DefaultVariationFragment,
  ModificationGroupFragment,
  ModificationGroupFragment_variations,
} from './api/__queries__';

export interface LocalVariation {
  id: string;
  name: string;
  price: number;
}

// The following two is the same, but need to be like this to keep the schema types.
export enum VARIATION_TYPE {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

export enum MODIFICATION_GROUP_TYPE {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

export interface Variation {
  id: string;
  name: string;
  selected: boolean;
  price: number;
  imageUrl: string | undefined;
  isDefault: boolean;
  isOutOfStock?: boolean;
}

// CombinedVariationGroup merges fields from both VariationGroup and ModificationGroup so we can use the same type for both solutions in the app.
export interface CombinedVariationGroup {
  id: string;
  title: string;
  type: VARIATION_TYPE | MODIFICATION_GROUP_TYPE;
  variations: Variation[];
  required: boolean;
  valid: boolean;
  expandedByDefault: boolean;
  preselectedVariations: string[]; // This will be an empty array for the old version.
}

type VariationHelpers = [
  CombinedVariationGroup[],
  (id: string) => void,
  LocalVariation[],
  LocalVariation[],
];

interface Props {
  appliedVariations: string[];
  defaultVariations: string[];

  appliedModificationGroups: string[]; // This is the modification groups that items have

  /* Array of IDs for variations which have already been selected */
  initialSelection?: string[];
  initialRemoved?: string[];
}

export const useVariations = ({
  appliedVariations: avIDs,
  defaultVariations: defIDs,
  appliedModificationGroups: mgIDs,
  initialSelection,
}: Props): VariationHelpers => {
  const { i18n } = useI18n();

  const inventory = useSelector((state) => state.inventory);
  const enableModifications = useSelector((state) => state.enableModifications);

  let [appliedVariations, defaultVariations] = useGetVariations(avIDs, defIDs);
  const modificationGroups = useGetModificationGroups(mgIDs);

  // If there is no data in the cache
  if (appliedVariations[0] === null) {
    appliedVariations = [];
    defaultVariations = [];
  }

  // Selected variations are all variations that are not equal to their inital state.
  // We dont differentiate between normal and default variations here
  const [selectedVariations, setSelectedVariations] = useState<string[]>(initialSelection || []);
  const [preselectedVariationsAssigned, setPreselectedVariationsAssigned] = useState<boolean>(
    false,
  );

  const getAllVariations = () => {
    if (enableModifications) {
      return modificationGroups.flatMap((mg) => mg.variations);
    }

    return flatMap(appliedVariations.map((av) => av.group.variations)).concat(defaultVariations);
  };

  // Create an array of all variations
  const variations = getAllVariations();

  // FYI: defaultVariations/preselectedVariations are the same thing, the variations that should be selected when the popup opens
  const preselectedVariations:
    | ModificationGroupFragment[]
    | DefaultVariationFragment[] = useMemo(() => {
    if (enableModifications) {
      return modificationGroups
        .filter((mg) => mg.type === 'MULTIPLE')
        .flatMap((mg) => {
          return mg.preselectedVariations
            .map((preselectedId) => {
              return mg.variations.find((variation) => variation.id === preselectedId);
            })
            .filter(
              (variation): variation is ModificationGroupFragment_variations =>
                variation !== undefined,
            );
        });
    }

    return defaultVariations;
  }, [defaultVariations, modificationGroups]);

  // Update the selected variations when the initial selection changes
  useEffect(() => {
    if (enableModifications && modificationGroups) {
      // Loop through each modificationGroup and get its preselectedVariations
      const newSelectedVariations = modificationGroups
        .filter((mg) => mg.type === 'SINGLE')
        .reduce(
          (acc, mg) => {
            mg.preselectedVariations.forEach((pv) => {
              if (!acc.includes(pv)) {
                acc.push(pv);
              }
            });
            return acc;
          },
          [...selectedVariations],
        );

      // Set the updated selected variations if there are new ones
      setSelectedVariations(newSelectedVariations);
    }

    // If the initial selection is not present in the selected variations
    // we add it to the selected variations
    if (initialSelection && selectedVariations.every((sv) => !initialSelection.includes(sv))) {
      setSelectedVariations((prev) => [...prev, ...initialSelection]);
    }
  }, [initialSelection?.length]);

  // Create an array of all groups from the old solution. New solution is just modificationGroups.
  const appliedVariationGroups = flatMap(appliedVariations.map((av) => av.group));

  // useEffect to set the preselectedVariations as selectedVariations
  useEffect(() => {
    const isThereGroups = enableModifications
      ? modificationGroups.length > 0
      : appliedVariationGroups.length > 0;

    if (!isThereGroups || preselectedVariationsAssigned) {
      return;
    }

    const preselectedWithoutOutOfStock = preselectedVariations.filter((v) =>
      checkOutOfStock(v.asItem?.id || v.id, inventory, true),
    );

    setPreselectedVariationsAssigned(true);
    setSelectedVariations((selectedVariations) => [
      ...selectedVariations,
      ...preselectedWithoutOutOfStock.map((v) => v.id),
    ]);
  }, [appliedVariationGroups, modificationGroups]);

  // Helper functions
  const isDefault = (id: string) => preselectedVariations.some((dv) => dv.id === id);
  const isSelected = (id: string) => selectedVariations.includes(id);

  // Helper function to handle single group selection
  const handleSingleGroupSelection = (
    selected: boolean,
    groupType: string,
    idsInGroup: string[],
    id: string,
  ) => {
    if (selected && groupType === MODIFICATION_GROUP_TYPE.SINGLE) {
      // If it's a single group and we are clicking an option that was already selected, do nothing.
      return;
    }

    // Remove any previous selection in this group
    const selectionsWithoutGroupNeighbours = selectedVariations.filter(
      (svID) => !idsInGroup.includes(svID),
    );

    // Set our single selection
    setSelectedVariations(Array.from(new Set([...selectionsWithoutGroupNeighbours, id])));
  };

  // Click handler when selecting a variation
  const onSelectVariation = (id: string) => {
    const selected = isSelected(id);

    const group = enableModifications
      ? modificationGroups.find((mg) => mg.variations.some((v) => v.id === id))
      : appliedVariationGroups.find((g) => g.variations.some((v) => v.id === id));

    // These types are actually the same, but with different names from the Schema.
    const groupType = enableModifications ? MODIFICATION_GROUP_TYPE.SINGLE : VARIATION_TYPE.SINGLE;

    if (selected) {
      if (group?.type === groupType) {
        // If it's a single group and we are clicking an option that was already selected, do nothing.
        return;
      }

      // Remove the variation from our selection
      setSelectedVariations(selectedVariations.filter((svID) => svID !== id));
    } else {
      const idsInGroup = (() => {
        if (!group) {
          return [];
        }

        if (group.type === MODIFICATION_GROUP_TYPE.SINGLE) {
          return (group.variations as ModificationGroupFragment['variations']).map(
            (vari) => vari.id,
          );
        } else if (group.type === VARIATION_TYPE.SINGLE) {
          return (group.variations as AppliedVariationFragment_group['variations']).map(
            (vari) => vari.id,
          );
        }

        return [];
      })();

      if (group?.type === groupType) {
        handleSingleGroupSelection(selected, groupType, idsInGroup, id);
      } else {
        // Add selection to the group
        setSelectedVariations(Array.from(new Set([...selectedVariations, id])));
      }
    }
  };

  // Find all removed variations and convert them to local (i18n) format
  const removedVariations: LocalVariation[] = useMemo(
    () =>
      preselectedVariations
        .filter((defVari) => selectedVariations.some((id) => id === defVari.id))
        .map((rv) => ({
          ...rv,
          name: i18n(rv.nameLang),
        })),
    [selectedVariations],
  );

  // Find all selected variations, excluding default variations
  const formattedSelectedVariations = difference(
    selectedVariations,
    preselectedVariations.map((ps) => ps.id),
  ).map((id) => {
    const variation = variations.find((v) => v.id === id);

    if (variation) {
      return {
        id: variation.id,
        name: i18n(variation.nameLang),
        price: variation.price,
      };
    } else
      return {
        id: '',
        name: '',
        price: 0,
      };
  });

  // Format our state to something that is easily consumable by the client
  const formattedGroups: CombinedVariationGroup[] = useMemo(
    () =>
      enableModifications
        ? modificationGroups.map((mg) => {
            let atLeastOneSelected = false;
            const variations = mg.variations.map((vari) => {
              const selected = isSelected(vari.id);
              const isPreselectedVariation = isDefault(vari.id);

              // Inverse selection set for default variations.
              // A selected default variation is actually an un-selection
              const markAsSelected = isPreselectedVariation ? !selected : selected;

              atLeastOneSelected = markAsSelected || atLeastOneSelected;

              // Check if the variation is present in the inventory universe,
              // and if it is, add the inventory metadata to the variation
              const isOutOfStock = checkOutOfStock(vari.asItem?.id || vari.id, inventory, true);
              const variName = i18n(vari.nameLang);

              return {
                id: vari.id,
                name: variName.length > 0 ? variName : vari.name,
                price: vari.price,
                selected: markAsSelected,
                imageUrl: vari.image?.file.url,
                isDefault: isPreselectedVariation,
                isOutOfStock,
              };
            });
            return {
              id: mg.id,
              title: i18n(mg.externalName),
              variations,
              type: mg.type,
              expandedByDefault: mg.expandedByDefault,
              required: mg.required,
              valid: !mg.required || (mg.required && atLeastOneSelected),
              preselectedVariations: mg.preselectedVariations,
            };
          })
        : appliedVariations
            .map((av) => {
              let atLeastOneSelected = false;
              const preselectedVariationsExist = av.group.preselectedVariations.length > 0;
              const variations = av.group.variations.map((vari) => {
                const selected = isSelected(vari.id);
                const isPreselectedVariation = isDefault(vari.id);

                // Inverse selection set for default variations.
                // A selected default variation is actually an un-selection
                const markAsSelected = isPreselectedVariation ? !selected : selected;

                atLeastOneSelected = markAsSelected || atLeastOneSelected;

                // Check if the variation is present in the inventory universe,
                // and if it is, add the inventory metadata to the variation
                const isOutOfStock = checkOutOfStock(vari.asItem?.id || vari.id, inventory, true);
                const variName = i18n(vari.nameLang);
                return {
                  id: vari.id,
                  name: variName.length > 0 ? variName : vari.name,
                  price: vari.price,
                  selected: !isOutOfStock && markAsSelected,
                  imageUrl: vari.image?.file.url,
                  isDefault: isPreselectedVariation,
                  isOutOfStock,
                };
              });
              return {
                id: av.id,
                title: i18n(av.group.title),
                variations,
                type: av.group.type,
                expandedByDefault: av.expandedByDefault || preselectedVariationsExist,
                required: av.group.required,
                valid: !av.group.required || (av.group.required && atLeastOneSelected),
                preselectedVariations: [], // This is only here to satisfy our combined group type. Ignore this, not used in the old solution.
              };
            })
            // Sort required first
            .sort((a, b) => +b.required - +a.required),
    [appliedVariations, selectedVariations],
  );

  return [formattedGroups, onSelectVariation, formattedSelectedVariations, removedVariations];
};
