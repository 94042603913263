import Button from 'Components/Button';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-mobile-datepicker';
import useI18n from '../../i18n';
import styled from 'styled-components';
import { DateTime } from 'luxon';

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  /**
   * time
   */
  time: string;
  /**
   * minimumPickupWaitTime
   */
  minimumPickupWaitTime: number;
  /**
   * onSubmit
   */
  onChange: (val: Date) => void;
  /**
   * nextOpeningHour, used for setting the minimum time if the store is currently closed
   */
  nextOpeningHour?: DateTime | null;
}

/**
 * DateTmePicker component
 */

const DateTimePicker: React.FC<Props> = ({
  onChange,
  time,
  minimumPickupWaitTime,
  nextOpeningHour,
}) => {
  const { i18n } = useI18n();
  const minTime = (nextOpeningHour || DateTime.now())
    .plus({ minutes: minimumPickupWaitTime })
    .set({ second: 0, millisecond: 0 })
    .toISO();
  const currentDate = minTime ? new Date(minTime) : new Date();
  const [chosenDate, setChosenDate] = useState(time === 'ASAP' ? currentDate : new Date(time));
  // Current date + 30 days is the maximum
  const newDate = new Date();
  const maxDate = newDate.setDate(newDate.getDate() + 30);

  const months: { [key: string]: string } = {
    '1': i18n('Date.Jan'),
    '2': i18n('Date.Feb'),
    '3': i18n('Date.Mars'),
    '4': i18n('Date.Apr'),
    '5': i18n('Date.May'),
    '6': i18n('Date.June'),
    '7': i18n('Date.July'),
    '8': i18n('Date.Aug'),
    '9': i18n('Date.Sep'),
    '10': i18n('Date.Oct'),
    '11': i18n('Date.Nov'),
    '12': i18n('Date.Dec'),
  };

  const days = [
    i18n('Date.Sun'),
    i18n('Date.Mon'),
    i18n('Date.Tue'),
    i18n('Date.Wed'),
    i18n('Date.Thu'),
    i18n('Date.Fri'),
    i18n('Date.Sat'),
  ];

  const datePickerConfig = {
    date: {
      format: (value: Date) =>
        days[value.getDay()] + ' ' + value.getDate() + ' ' + months[value.getMonth() + 1] + '.',
      caption: 'Day',
      step: 1,
    },
    hour: {
      format: 'hh',
      caption: 'Hour',
      step: 1,
    },
    minute: {
      format: 'mm',
      caption: 'Min',
      step: 1,
    },
  };

  const handleChange = (val: Date) => {
    setChosenDate(val);
    onChange(val);
  };

  return (
    <Wrapper>
      <DatePicker
        isPopup={false}
        showHeader={false}
        showFooter={false}
        theme="ios"
        dateConfig={datePickerConfig}
        min={currentDate}
        max={new Date(maxDate)}
        value={chosenDate}
        onChange={(val: Date) => handleChange(val)}
      />
    </Wrapper>
  );
};

export default DateTimePicker;
