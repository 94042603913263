import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useApolloClient } from '@apollo/client';
import { getOfferDetails, getOfferDetailsVariables } from './__queries__';
import OfferDialog from '../../../Scenes/Home/Components/OfferDialog';
import { useSelector } from '../../../Hooks';

const GET_OFFER_DETAILS = gql`
  query getOfferDetails($id: ID!) {
    offer: Offer(id: $id) {
      id
      createdAt
      type
      discountRate
      discountType
      price
      nameLang {
        id
        se
        en
        no
        es
      }
      descriptionLang {
        id
        se
        en
        no
        es
      }
      venues {
        id
      }
      image {
        id
        file {
          id
          url
        }
      }
    }
  }
`;
const OfferToken = () => {
  const user = useSelector((state) => state.user);
  const [offerId, setOfferId] = useState<string | undefined>(undefined);
  const [tokenId, setTokenId] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<any>(null);
  const client = useApolloClient();

  const [getOfferDetails, { data: dataOffer }] = useLazyQuery<
    getOfferDetails,
    getOfferDetailsVariables
  >(GET_OFFER_DETAILS);

  const clearOffer = () => {
    setOfferId(undefined);
    setTokenId(undefined);
  };

  const onMessage = (msg: any) => {
    setMessage(msg);
  };

  // handle message
  useEffect(() => {
    if (!message || !user) {
      return;
    }

    let data = message?.data;

    try {
      data = typeof data === 'string' ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
    }

    if (data?.offerId) {
      setOfferId(data.offerId);
    }

    if (data?.tokenId) {
      setTokenId(data.tokenId);
    }

    setMessage(null);
  }, [message, user]);

  useEffect(() => {
    if (!offerId || !tokenId) {
      return;
    }
    getOfferDetails({
      variables: {
        id: offerId,
      },
    });
  }, [offerId, tokenId]);

  useEffect(() => {
    // Add the event listener for messages passed from app wrapper
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    // To prevent memory leaks, remove all event listeners on demount
    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const lightOfferData = client.readFragment({
    id: offerId,
    fragment: gql`
      fragment currentOffer on Offer {
        id
        createdAt
        type
        discountRate
        discountType
        price
        nameLang {
          id
          se
          en
          no
          es
        }
        descriptionLang {
          id
          se
          en
          no
          es
        }
        venues {
          id
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
    `,
  });

  const offer = offerId ? dataOffer?.offer || lightOfferData : null;
  if (!offer) {
    return null;
  }

  if (Object.keys(offer).length === 0) {
    return null;
  }
  return <OfferDialog activeOffer={offer} onClose={clearOffer} tokenId={tokenId} />;
};

export default OfferToken;
