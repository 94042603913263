import Button from 'Components/Button';
import { useConfig } from 'Components/ConfigProvider';
import DateTimePickerDesktop from 'Components/DateTimePickerDesktop';
import DateTimePicker from 'Components/DateTmePicker';
import { SERVICE_TYPE, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useLogger, useSelector } from 'Hooks';
import useOpeningHours from 'Hooks/useOpeningHours';
import { FC, useEffect, useState } from 'react';
import TabSelector from 'Scenes/Cart/Components/TabSelector';
import styled from 'styled-components';
import { useStorage } from 'Components/Storage';
import { useHistory } from 'react-router-dom';
import useI18n from 'i18n';
import { DEVICE_SIZES, getDeviceSize } from 'utils/deviceSize';
import { gql, useQuery } from '@apollo/client';
import { minimumPickupTimeQuery, minimumPickupTimeQueryVariables } from './__queries__';
import { parseServiceType } from 'Components/ServiceTypePicker';
import { DateTime } from 'luxon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #fff;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: 400px;
  max-height: 600px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    min-height: 600px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 8px 0 16px !important;
  font-size: 12px;

  li {
    margin-bottom: 4px;
  }
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  z-index: 0 !important;
`;

const PICKUP_TIME_QUERY = gql`
  query minimumPickupTimeQuery($id: ID!) {
    venue: Venue(id: $id) {
      id
      minimumPickupWaitTime
    }
  }
`;

interface Props {
  venueId: string;
  isInCart?: boolean;
  onSubmitted?: () => void;
  initialTimePicked?: string;
}

const PreOrderTimePicker: FC<Props> = ({ venueId, isInCart, onSubmitted, initialTimePicked }) => {
  const { i18n } = useI18n();
  const logger = useLogger('pre-order-time-picker');
  const [closed, setClosed] = useState(false);
  const config = useConfig();
  const minimumPickupTime = useSelector((state) => state.minimumPickupTime);
  const [time, setTime] = useState<string>(initialTimePicked || 'ASAP');
  const [_, updateStorage] = useStorage();
  const [serviceType, setServiceType] = useState<SERVICE_TYPE>('TAKE_AWAY');
  const {
    today,
    openingHoursList,
    checkOpeningHoursForDate,
    getNextOpeningTime,
  } = useOpeningHours(venueId);
  const deviceSize = getDeviceSize();

  // Check if store is currently closed and that it opens later today
  const isBeforeTodaysOpening =
    !today.isOpen && today.from && DateTime.now() < DateTime.fromFormat(today.from, 'HH:mm');

  // If the store is closed and opens later today, we want to show the next opening time.
  // If it does not open later today or is closed today, we want to show the next opening time (which is another day)
  // If it is open now we return null since we don't need to set a specific minimum date/time
  const nextOpeningHour = isBeforeTodaysOpening
    ? DateTime.fromFormat(today.from, 'HH:mm')
    : !today.isOpen
    ? getNextOpeningTime()
    : null;

  // If we are not in the cart we need to get the time from the venueId passed in props and not use the state because the state does not have the venue yet
  const pickupTimeQuery = useQuery<minimumPickupTimeQuery, minimumPickupTimeQueryVariables>(
    PICKUP_TIME_QUERY,
    {
      variables: {
        id: venueId,
      },
      pollInterval: 30000,
      skip: !venueId || isInCart,
    },
  );

  const shoppingCartApi = useShoppingCartApi();
  const userId = useSelector((state) => state.user?.id);
  const history = useHistory();

  const minimumPickupWaitTime =
    pickupTimeQuery.data?.venue.minimumPickupWaitTime ??
    (config.SYNC_ETA ? minimumPickupTime : 15);

  useEffect(() => {
    if (!time || time === 'ASAP') {
      setClosed(false);
      return;
    }
    const openingHours = checkOpeningHoursForDate(new Date(time));

    if (!openingHours.isOpen) {
      setClosed(true);
    } else {
      setClosed(false);
    }
  }, [time]);

  const handleSubmit = () => {
    if (isInCart) {
      // Cart is already created, here we only change the pickup time
      shoppingCartApi.setPickupTime(time);
      onSubmitted && onSubmitted();
      return;
    }

    // If time is asap but the store is closed, we set the pickup time to the next opening time
    const pickupTime = time === 'ASAP' && nextOpeningHour ? nextOpeningHour.toISO() : time;

    const id = shoppingCartApi.createCart(venueId, serviceType, null, false, pickupTime);
    logger.info('Cart created', {
      cartId: id,
      venueId,
      serviceType,
      userId: userId ?? 'anonymous',
    });

    updateStorage({
      serviceType,
      qrOrder: false,
    });

    history.push({ pathname: `/order/${venueId}` });
  };

  return (
    <Wrapper>
      {!isInCart && <h3>{i18n('Store.PreOrder.SelectTime')}</h3>}
      <List>
        {openingHoursList.map((day) => (
          <li key={day.day}>
            <strong>{day.day}</strong> - {day.openingHours}
          </li>
        ))}
      </List>
      {!isInCart && (
        <TabSelector
          list={config.ALLOWED_SERVICE_TYPES.map((type) => {
            const st = parseServiceType(type, i18n);
            return { id: st.id, name: st.name };
          })}
          selected={serviceType}
          onSelect={(id) => setServiceType(id as SERVICE_TYPE)}
        />
      )}
      {deviceSize === DEVICE_SIZES.SMALL ? (
        <DateTimePicker
          minimumPickupWaitTime={minimumPickupWaitTime}
          onChange={(newTime) => setTime(newTime.toISOString())}
          time={time}
          nextOpeningHour={nextOpeningHour}
        />
      ) : (
        <DateTimePickerDesktop
          onSubmit={setTime}
          time={time}
          minimumPickupWaitTime={minimumPickupWaitTime}
          nextOpeningHour={nextOpeningHour}
        />
      )}

      <ButtonWrapper>
        {closed && time !== 'ASAP' && <Error>{i18n('Store.PreOrder.SelectTime.Invalid')}</Error>}
        <Button
          text="Save"
          handleClick={handleSubmit}
          width="100%"
          disabled={closed && time !== 'ASAP'}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PreOrderTimePicker;
