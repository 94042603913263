import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
  padding: 5px;
  border: 2px solid #212124;
  min-height: 52px;
`;

const Item = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  color: #212124;
  transition: color 0.3s ease;
  font-weight: 600;
  font-size: 14px;

  &.selected {
    color: #fff;
  }
`;

const SlidingBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #212124;
  transition: left 0.3s ease, width 0.3s ease;
  border-radius: 40px;
  z-index: 0;
  height: calc(100% - 8px);
  margin: 4px 0;
  z-index: 0 !important;
`;

interface Props {
  list: { id: string; name: string }[];
  selected: string;
  onSelect: (id: string) => void;
}

const TabSelector: FC<Props> = ({ list, selected, onSelect }) => {
  const [position, setPosition] = useState<{ left: number; width: number }>({ left: 0, width: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  // Adjust the position of the sliding background based on the selected item
  useEffect(() => {
    const selectedIndex = list.findIndex((item) => item.id === selected);
    if (selectedIndex >= 0 && containerRef.current) {
      const selectedElement = containerRef.current.children[selectedIndex] as HTMLElement;
      if (selectedElement) {
        // Calculate and set position for the background
        setPosition({
          left: selectedElement.offsetLeft,
          width: selectedElement.offsetWidth,
        });
      }
    }
  }, [selected, list]);

  const handleSelect = (id: string) => {
    onSelect(id);
  };

  return (
    <Wrapper className="select-bar-container" ref={containerRef}>
      {list.map((item) => (
        <Item
          key={item.id}
          className={`select-bar-item ${selected === item.id ? 'selected' : ''}`}
          onClick={() => handleSelect(item.id)}>
          {item.name}
        </Item>
      ))}

      {/* Sliding background */}
      <SlidingBackground
        style={{
          left: `${position.left}px`,
          width: `${position.width}px`,
        }}
      />
    </Wrapper>
  );
};

export default TabSelector;
