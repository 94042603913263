import React from 'react';
import styled from 'styled-components';
import Icon from './CheckIcon';
import RadioIcon from '../../../Components/RadioIcon';
import { useCurrency, useBridgeApi } from 'Hooks';
import { ExpandedGroupItem, GROUP_TYPE } from './Group';
import useI18n from 'i18n';
import Quantity from './Quantity';

const Wrapper = styled.div<{ cursor: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: solid 1px rgb(224, 224, 227);
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};

  :last-child {
    border-bottom: none;
  }
`;

const Name = styled.strong<{ isOutOfStock?: boolean }>`
  flex: 1;
  padding: 0 10px 0 0;
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  ${({ isOutOfStock }) =>
    isOutOfStock &&
    `
    opacity: 0.5;
    `}
`;

const Price = styled.strong`
  color: rgb(97, 97, 100);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

interface Props extends ExpandedGroupItem {
  /**
   * onSelect
   */
  onSelect: ((id: string) => void) | null;
  /**
   * type
   */
  type: GROUP_TYPE | null;
  /**
   * onChangeQuantity
   */
  onChangeQuantity: (quantity: string) => void;
  /**
   * enableMultiSelect
   */
  enableMultiSelect?: boolean;
  /**
   * selectionDisabled
   */
  selectionDisabled?: boolean;
}

/**
 * GroupItem component
 */
const GroupItem = ({
  id,
  name,
  price,
  onSelect,
  type,
  selected,
  isOutOfStock,
  quantity,
  maxQuantity,
  enableMultiSelect,
  selectionDisabled,
  onChangeQuantity,
}: Props) => {
  const api = useBridgeApi();
  const { i18n } = useI18n();
  const { formatAmount } = useCurrency();
  const quantityEnable = quantity !== undefined;

  return (
    <Wrapper
      cursor={Boolean(onSelect)}
      onClick={() => {
        if (!onSelect || !id || isOutOfStock || quantityEnable) {
          return;
        }

        api.vibrate('impactLight');
        onSelect(id);
      }}>
      {(() => {
        if (quantityEnable) {
          return null;
        }
        if (type === GROUP_TYPE.MULTIPLE) {
          return <Icon active={selected} />;
        } else if (type === GROUP_TYPE.SINGLE) {
          return <RadioIcon active={isOutOfStock ? false : selected} />;
        }
        return null;
      })()}

      <Name isOutOfStock={isOutOfStock}>{name}</Name>
      {isOutOfStock && <Price>{i18n('General.OutOfStock')}</Price>}
      {!isOutOfStock && price !== 0 && price && <Price>+{formatAmount(price)}</Price>}
      {quantityEnable && (
        <Quantity
          setQuantity={onChangeQuantity}
          quantity={quantity!.toString()}
          enableMultiSelect={enableMultiSelect}
          selectionDisabled={selectionDisabled}
          small
          minValue={0}
          maxValue={maxQuantity}
          disabled={isOutOfStock}
        />
      )}
    </Wrapper>
  );
};

export default GroupItem;
