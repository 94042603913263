import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import logo from '../../Assets/Images/logo.svg';

import useAuth from 'Hooks/useAuth';
import DrawerNavigation from 'Components/DrawerNavigation';
import Alert from 'Components/Dialog/Alert';
import Routes from './Routes';
import UserManager from './UserManager';
import CompanyManager from './CompanyManager';
import OfferManager from './OfferManager';
import InventoryManager from './InventoryManager';
import DeviceToken from '../DeviceToken';
import OfferNotification from '../../Components/Notifications/Offer';
import NewsNotification from '../../Components/Notifications/News';
import OrderHistoryNotification from '../../Components/Notifications/OrderHistory';
import UpdateAuthTokenFromOld from '../../Components/UpdateAuthTokenFromOld';
import Synchronizers from 'Components/Synchronizers';
import ResetURIToHome from 'Components/ResetURIToHome';
import Signin from 'Scenes/Signin';
import Signup from 'Scenes/Signup';
import ForgotPassword from '../../Scenes/ForgotPassword';
import VerifyAccount from '../../Scenes/VerifyAccount';

import { useDispatch, useSelector } from 'Hooks';

import * as actions from 'actions';
import { useStorage } from 'Components/Storage';
import { useConfig } from 'Components/ConfigProvider';
import { Language } from 'Types';
import CartManager from './CartManager';
import PromoCodeManager from 'Components/PromoCodeManager';
import { DEFAULT_LOCALE } from 'actions';
import SignInBottomModal from '../SignInBottomModal';
import MenuManager from 'Components/MenuManager';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';

const Container = styled.div<{ paddingLeft: number }>`
  min-height: var(--height);
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin: 0 0 0 ${({ paddingLeft }) => `${paddingLeft}px`};
    transform: translate(0px, 0px);
  }
`;

const LoginWrapper = styled.div`
  min-height: var(--height);
  z-index: 99;
  background: white;
`;

function App() {
  const config = useConfig();
  const { hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const location = useLocation();
  const { pathname } = useLocation();
  const [storage, updateStorage, storageLoading] = useStorage();
  const cartId = useSelector((state) => state.cartId);
  const defaultLanguage = useSelector((state) => state.defaultLanguage);
  const languages = useSelector((state) => state.companyLanguages);
  const loginScreen = useSelector((state) => state.loginScreen);
  const loginModal = useSelector((state) => state.loginModal);
  const dispatch = useDispatch();
  const { serviceType } = useShoppingCart();

  const onResize = () => {
    if (window.innerWidth >= 768) {
      dispatch(actions.toggleMainMenu(true));
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // saving redirect path after authorization to store
  //useRedirectPath();

  /**
   * Initialize google analytics
   */
  useEffect(() => {
    if (storage.analyticsConsent == false) {
      // @ts-ignore: Cannot index window using string
      window[`ga-disable-${config.ANALYTICS_ID}`] = true;
    } else {
      ReactGA.initialize(config.ANALYTICS_ID, {
        gtagOptions: {
          analytics_storage: 'denied',
        },
        gaOptions: {
          anonymizeIp: true,
        },
      });
    }
  }, [storage.analyticsConsent]);

  /**
   * On mount, set cart ID if we have one in storage.
   * This makes page reloads retain state
   */
  /*   useEffect(() => {
    if (storage.cartId && (config.ANONYMOUS_PURCHASE || pathname.includes('/order'))) {
      dispatch(actions.setCartId(storage.cartId));
    }
    if (storage.redirectPath && config.ANONYMOUS_PURCHASE) {
      dispatch(actions.setRedirectPath(storage.redirectPath));
    }
  }, [storage.cartId]); */

  /**
   * On mount, set language to browser language or company default language.
   * Commenting this out since its interferring with the language selector and the browserLang codes do not seem to be compatible with our Language type as of now
   */
  /*   useEffect(() => {
    const browserLang = window.navigator.language.slice(0, 2);
    console.log('Browser language', browserLang);
    const isSupportedLanguage =
      browserLang === 'se' || browserLang === 'en' || browserLang === 'es';
    if (isSupportedLanguage) {
      updateStorage({ language: browserLang as Language });
    } else {
      updateStorage({ language: defaultLanguage });
    }
  }, []); */

  useEffect(() => {
    const validLanguages = ['en', 'se', 'es', 'no', 'tr', 'dk'];

    // Wait for languages to be loaded
    if (!defaultLanguage || languages.length === 0) {
      return;
    }

    // If there is a valid stored language, use it
    if (
      storageLoading ||
      (storage.language &&
        languages.includes(storage.language) &&
        validLanguages.includes(storage.language))
    ) {
      return;
    }

    // If the stored language is invalid because of changes of lang keys,
    // set it to the default language (we want to avoid this situation)
    if (storage.language && !validLanguages.includes(storage.language)) {
      updateStorage({ language: defaultLanguage });
      return;
    }

    // If there is no stored language, set it based on company configs
    let language: Language | null = config.DEFAULT_LANGUAGE || DEFAULT_LOCALE;

    if (config.IS_CLOUD_CONFIG) {
      language = defaultLanguage;
    }

    updateStorage({ language });
  }, [defaultLanguage, storageLoading, languages]);

  /**
   * Update local storage when cart ID changes
   */
  useEffect(() => {
    if (!cartId || cartId === storage.cartId) {
      return;
    }
    updateStorage({
      cartId,
    });
  }, [cartId]);

  useEffect(() => {
    // Wait a few ms with tracking page until title has changed
    setTimeout(() => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
    }, 200);
  }, [location]);

  // Check if cookie banner should show
  // Don't show in app-mode (hasNativeWrapper)
  //const showCookieBanner = !window.hasNativeWrapper && storage.cookieConsent == null;

  return (
    <>
      <Helmet>
        <title>{config.DISPLAY_NAME}</title>
        <link rel="icon" href={logo} sizes="32x32" />
      </Helmet>
      <CartManager />
      <UserManager />
      <UpdateAuthTokenFromOld />
      <CompanyManager />
      <OfferManager />
      <PromoCodeManager />
      <InventoryManager />
      <DrawerNavigation isSignedIn={isSignedIn} />
      <Container paddingLeft={window.sidebarWidth}>
        <Routes />
        <Alert />
        {loginScreen && (
          <LoginWrapper>
            {loginScreen === 'signin' && <Signin />}
            {loginScreen === 'signup' && <Signup />}
            {loginScreen === 'forgot-password' && <ForgotPassword />}
            {loginScreen === 'verify-account' && <VerifyAccount />}
          </LoginWrapper>
        )}
        <SignInBottomModal show={loginModal} />
      </Container>
      <DeviceToken />
      <OfferNotification />
      <NewsNotification />
      <OrderHistoryNotification />
      <Synchronizers />
      <ToastContainer />
      <ResetURIToHome />
      <MenuManager serviceType={serviceType} />
      {/* showCookieBanner && <CookieBanner /> */}
    </>
  );
}

export default App;
