import { useRef } from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import { VenuesWithDistance } from '../index';
import useOpeningHours from 'Hooks/useOpeningHours';
import ServiceTypePicker from 'Components/ServiceTypePicker';

const Wrapper = styled.div<{ active: boolean }>`
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
`;

const Inner = styled.div<{ zIndex: number; active: boolean }>`
  z-index: ${(props) => props.zIndex};
  box-shadow: 0 0 7px 4px ${(props) => (props.active ? 'rgba(0,0,0,0.38)' : 'transparent')};
  position: ${(props) => (props.active ? 'absolute' : 'relative')};
  width: 100%;

  @media (min-width: 992px) {
    width: ${(props) => (props.active ? '500px' : '100%')};
    left: ${(props) => (props.active ? '50%' : 0)};
    transform: ${(props) => (props.active ? 'translateX(-50%)' : 'translateX(0)')};
  }
`;

const Divider = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}px`};
`;

const Fade = styled.div<{ paddingLeft: number }>`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  height: var(--height);
  width: 100%;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
`;

const VenueName = styled.div`
  color: #212124;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 12px 0;
`;

const Venue = styled.div<{ activeVenue: boolean }>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background: #ffffff;
  position: relative;
  border-bottom: solid 6px rgb(238, 238, 241);

  @media (min-width: 768px) {
    &:hover ${VenueName} {
      text-decoration: ${({ activeVenue }) => (activeVenue ? 'none' : 'underline')};
    }
  }
`;

const VenueInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VenueInfo = styled.div``;

const Distance = styled.div`
  padding: 0 18px;
  background: #f5f5f8;
  border-radius: 16px;
  height: 32px;
  color: #212124;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
`;

const VenueRow = styled.div`
  color: rgb(97, 97, 100);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 16px;
  margin: 0 0 2px 0;
`;

const ServiceTypeWrapper = styled.div`
  background: rgb(245, 245, 248);
  padding: 24px 0 40px;
`;

const formatDistance = (venue: VenuesWithDistance) => {
  const { distance } = venue;

  if (!distance && distance !== 0) {
    return null;
  }

  if (distance === 0) {
    return `0m`;
  }

  if (distance < 1000) {
    return `${distance}m`;
  }

  if (distance >= 1000) {
    return `${Math.round(distance / 1000)}km`;
  }

  return null;
};

type Props = {
  venue: VenuesWithDistance;
  activeVenue: string | null;
  setActiveVenue: (venueId: string | null) => void;
};

const VenueItem = ({ venue, activeVenue, setActiveVenue }: Props) => {
  const { i18n } = useI18n();
  const venueItemRef = useRef<HTMLDivElement>(null);

  const openinghours = useOpeningHours(venue.id);

  const formattedDistance = formatDistance(venue);

  const active = activeVenue === venue.id;
  const zIndex = active ? 1 : 0;
  const fadeStyle: any = active ? { opacity: 1, visibility: 'visible' } : {};

  const handleClick = (id: string | null) => {
    setActiveVenue(id);
    venueItemRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const zip = venue.zip ?? venue.location.zip;

  return (
    <Wrapper ref={venueItemRef} active={active}>
      <Fade
        onClick={() => handleClick(null)}
        style={fadeStyle}
        paddingLeft={window.sidebarWidth}
      />
      <Inner zIndex={zIndex} active={active}>
        <Venue onClick={() => handleClick(venue.id)} activeVenue={Boolean(activeVenue)}>
          <VenueName>{venue.name}</VenueName>
          <VenueInfoWrapper>
            <VenueInfo>
              <VenueRow>{venue.location.street ?? venue.location.name}</VenueRow>
              <VenueRow>
                {zip} {venue.location.city}
              </VenueRow>
              <VenueRow>
                {`${i18n('StoreList.Today')}: `}
                {openinghours.today.from === '--'
                  ? i18n('StoreList.Closed')
                  : openinghours.today.from + '-' + openinghours.today.to}
              </VenueRow>
            </VenueInfo>
            {formattedDistance && <Distance>{formattedDistance}</Distance>}
          </VenueInfoWrapper>
        </Venue>
        {active && (
          <ServiceTypeWrapper>
            <ServiceTypePicker venueId={venue.id} />
          </ServiceTypeWrapper>
        )}
      </Inner>
      {active && <Divider height={136} />}
    </Wrapper>
  );
};

export default VenueItem;
