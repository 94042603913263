import styled from 'styled-components';
import { useState, useEffect, useMemo } from 'react';
import useI18n from '../../i18n';
import Select from './Select';
import { DateTime } from 'luxon';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  background-color: #fff;
  width: 100%;
  margin-top: 16px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
`;

const SelectWrapper = styled.div`
  width: 50%;
  padding: 10px 10px 0 10px;
`;

const correctTime = (date: string): DateTime => {
  const current = DateTime.fromISO(date);

  // Ensure the parsed DateTime is valid
  if (!current.isValid) {
    throw new Error(`Invalid date: ${date}`);
  }

  const roundedMinutes = Math.floor(current.minute / 15) * 15; // Round down to the nearest 15 minutes
  return current.set({ minute: roundedMinutes, second: 0 });
};

function roundToNextQuarter(dt: DateTime): DateTime {
  const minutes = dt.minute;
  const remainder = minutes % 15;
  const adjustment = remainder === 0 ? 0 : 15 - remainder; // Adjust only if not on a 15-minute mark
  return dt.plus({ minutes: adjustment }).set({ second: 0, millisecond: 0 });
}

interface Props {
  /**
   * time
   */
  time: string;
  /**
   * onSubmit
   */
  onSubmit: (dateTime: string) => void;

  minimumPickupWaitTime: number;

  /**
   * nextOpeningHour, used for setting the minimum time if the store is currently closed
   */
  nextOpeningHour?: DateTime | null;
}

/**
 * DateTmePicker component
 */

const DateTimePickerDesktop = ({
  time,
  onSubmit,
  minimumPickupWaitTime,
  nextOpeningHour,
}: Props) => {
  const minutesInterval = 15;
  const maxDay = 30;
  const averageMinutes = Math.floor(minutesInterval / 2);
  const { i18n } = useI18n();
  const TODAY = i18n('Cart.Pickuptime.Today');
  const minDate = useMemo(() => {
    if (nextOpeningHour && nextOpeningHour.isValid) {
      return nextOpeningHour.set({ second: 0, millisecond: 0 });
    }

    const baseDate = DateTime.now()
      .plus({ minutes: minimumPickupWaitTime })
      .set({ second: 0, millisecond: 0 });

    return roundToNextQuarter(baseDate);
  }, [minimumPickupWaitTime, nextOpeningHour]);

  const maxDate = minDate.plus({ days: maxDay });
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>();

  const months: { [key: string]: string } = {
    '1': i18n('Date.Jan'),
    '2': i18n('Date.Feb'),
    '3': i18n('Date.Mars'),
    '4': i18n('Date.Apr'),
    '5': i18n('Date.May'),
    '6': i18n('Date.June'),
    '7': i18n('Date.July'),
    '8': i18n('Date.Aug'),
    '9': i18n('Date.Sep'),
    '10': i18n('Date.Oct'),
    '11': i18n('Date.Nov'),
    '12': i18n('Date.Dec'),
  };

  const days = [
    i18n('Date.Mon'),
    i18n('Date.Tue'),
    i18n('Date.Wed'),
    i18n('Date.Thu'),
    i18n('Date.Fri'),
    i18n('Date.Sat'),
    i18n('Date.Sun'),
  ];

  const formatDate = (dateStr: string): string => {
    const dateObj = DateTime.fromISO(dateStr);

    if (!dateObj.isValid) {
      console.log('Invalid date passed to formatDate:', dateStr);
      throw new Error(`Invalid date: ${dateStr}`);
    }

    const day = days[dateObj.weekday - 1];
    const date = dateObj.day;
    const month = months[dateObj.month.toString()];
    return `${day}, ${date} ${month}`;
  };

  // Set initial time
  useEffect(() => {
    let newTime: string | null;
    let newDate;

    if (time === 'ASAP') {
      newDate = minDate.toISO();
      newTime = minDate.toISO();
    } else {
      const correctedTime = DateTime.fromISO(time) < minDate ? minDate : correctTime(time);

      newDate = correctedTime.toISO();
      newTime = correctedTime.toISO();
    }

    setSelectedDate(newDate);
    setSelectedTime(newTime);
  }, [time]);

  const onSelectDate = (val: string) => {
    const selectedDateTime = DateTime.fromISO(val);
    const selectedTimeObj = selectedTime ? DateTime.fromISO(selectedTime) : null;

    // Check if there's a previously selected time and align it to the new date
    const newTime =
      selectedTimeObj && selectedTimeObj.isValid
        ? selectedDateTime.set({
            hour: selectedTimeObj.hour,
            minute: selectedTimeObj.minute,
          })
        : selectedDateTime.startOf('day'); // Default to midnight if no time is selected

    // Ensure the new time is valid (e.g., after the minDate)
    const finalTime = newTime < minDate ? minDate : newTime;

    // Update selected date and time
    setSelectedDate(val);
    setSelectedTime(finalTime.toISO());

    // Submit the new date-time
    handleSubmit(finalTime.toISO()!);
  };

  const onSelectTime = (val: string) => {
    if (val === 'ASAP') {
      setSelectedTime(minDate.toISO());
    } else {
      setSelectedTime(val);
    }

    handleSubmit(val);
  };

  const handleSubmit = (val: string) => {
    const parsedTime = DateTime.fromISO(val);
    if (val === 'ASAP' || !parsedTime.isValid) {
      onSubmit(minDate.toISO() ?? 'ASAP');
      return;
    }

    const result = parsedTime.plus({ minutes: averageMinutes }).toISO();

    onSubmit(result);
  };

  const days_arr = useMemo(() => {
    const dates = [];
    let current = minDate;

    while (current <= maxDate) {
      if (current.hasSame(DateTime.now(), 'day') && current.hasSame(minDate, 'day')) {
        dates.push({
          label: TODAY,
          value: current.toISO(),
        });
      } else if (current.isValid) {
        dates.push({
          label: formatDate(current.toISO() as string),
          value: current.toISO(),
        });
      }

      current = current.plus({ days: 1 }); // Increment by 1 day
    }

    return dates;
  }, [minDate, maxDate, TODAY]);

  useEffect(() => {
    if (!days_arr.length) {
      return;
    }

    if (!days_arr[0].value) {
      return;
    }

    if (selectedTime === 'ASAP') {
      setSelectedTime(days_arr[0].value);
    }
  }, [days_arr]);

  const times_arr = useMemo(() => {
    if (!selectedDate) {
      return [];
    }

    const times = [];
    const selectedDateTime = DateTime.fromISO(selectedDate); // Convert selectedDate to DateTime
    const isNextDay = minDate < selectedDateTime.startOf('day'); // Compare day only
    const startTime = isNextDay ? selectedDateTime.startOf('day') : minDate; // Start at midnight or minDate
    const closeTime = selectedDateTime.plus({ days: 1 }).startOf('day'); // End at midnight next day

    let current = correctTime(startTime.toISO() as string); // Ensure starting time is corrected

    while (current < closeTime) {
      const from = current.toISO();
      const to = current.plus({ minutes: minutesInterval }).toISO();

      times.push({
        label: `${current.toFormat('HH:mm')} - ${current
          .plus({ minutes: minutesInterval })
          .toFormat('HH:mm')}`,
        value: from,
      });

      current = current.plus({ minutes: minutesInterval });
    }

    return times;
  }, [selectedDate, minDate]);

  useEffect(() => {
    if (!times_arr.length) {
      return;
    }

    if (!times_arr[0].value) {
      return;
    }

    if (!selectedTime || selectedTime === 'ASAP') {
      setSelectedTime(times_arr[0].value);
    }
  }, [times_arr]);

  const formattedDate = useMemo(() => {
    if (!selectedDate) {
      return '';
    }

    const selectedDateTime = DateTime.fromISO(selectedDate); // Parse selectedDate into a DateTime object

    // Check if the selected date is today
    if (selectedDateTime.hasSame(DateTime.now(), 'day')) {
      return TODAY; // Return the localized "Today" string
    }

    if (!selectedDateTime.isValid) {
      return '';
    }

    return formatDate(selectedDateTime.toISO()); // Use formatDate with the ISO string
  }, [selectedDate, minDate]);

  const formattedTime = useMemo(() => {
    if (!selectedTime) {
      return '';
    }

    return `${DateTime.fromISO(selectedTime).toFormat('HH:mm')}-${DateTime.fromISO(selectedTime)
      .plus({
        minutes: minutesInterval,
      })
      .toFormat('HH:mm')}`;
  }, [selectedTime]);

  const findSelectedDateItem = (value: string): boolean => {
    if (!selectedDate) {
      return false;
    }

    const valueDate = DateTime.fromISO(value);
    const selectedDateTime = DateTime.fromISO(selectedDate);

    return valueDate.hasSame(selectedDateTime, 'day'); // Compare only the day part
  };

  const findSelectedTimeItem = (value: string): boolean => {
    if (!selectedTime) {
      return false;
    }

    // If selectedTime and value are directly equal (ASAP case)
    if (selectedTime === value) {
      return true;
    }

    if (selectedTime === 'ASAP') {
      return false;
    }

    // When both value and selectedTime are objects
    const valueFrom = DateTime.fromISO(value);
    const selectedTimeFrom = DateTime.fromISO(selectedTime);

    return valueFrom.equals(selectedTimeFrom); // Compare exact timestamps
  };

  return (
    <Wrapper>
      <Content>
        <SelectWrapper>
          <Select
            items={days_arr}
            onChange={onSelectDate}
            formattedValue={formattedDate}
            findSelectedItem={findSelectedDateItem}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            items={times_arr}
            onChange={onSelectTime}
            formattedValue={formattedTime}
            findSelectedItem={findSelectedTimeItem}
          />
        </SelectWrapper>
      </Content>
    </Wrapper>
  );
};

export default DateTimePickerDesktop;
