import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DateTimePicker from 'Components/DateTmePicker';
import Button from 'Components/Button';
import Loader from 'Components/Loader';
import Modal from 'Components/BottomModal';
import dayjs from 'dayjs';
import useI18n from 'i18n';
import getLocale from 'i18n/getLocale';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useBridgeApi, useLogger, useSelector } from 'Hooks';
import { ModalType } from '..';
import AdyenDropIn from '../Components/AdyenDropIn';
import OpacityTouchable from 'Components/OpacityTouchable';
import { ACTIVATE_PROMOCODE } from '..';
import { useMutation } from '@apollo/client';
import { ActivatePromoCode } from '../__queries__/';
import { DISCOUNT_TYPE } from '../../../../__queries__/globalTypes';
import { useConfig } from 'Components/ConfigProvider';
import PreOrderTimePicker from 'Components/PreOrderTimePicker';

const ModalText = styled.p`
  text-align: center;
  font-size: 18px;
  font-family: 'Eina01';
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 16px;
`;

const FailureWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  height: 40%;
  margin-bottom: 16px;
`;

const SuccessWrapper = styled.div`
  padding: 80px;
  margin-bottom: 20px;
  display: flex;
  box-sizing: border-box;
  height: calc(var(--height) - 220px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface Props {
  /**
   * mode
   */
  mode: string;
  /**
   * error
   */
  error: string | null;
  /**
   * sessionData
   */
  sessionData: any;
  /**
   * setMode
   */
  setMode: (mode: ModalType) => void;
  /**
   * setError
   */
  setError: React.Dispatch<React.SetStateAction<string>>;
  /**
   * getSession
   */
  getSession: () => Promise<void>;
  /**
   * setPayload
   */
  setPayload: React.Dispatch<React.SetStateAction<string>>;
  /**
   * updateDetails
   */
  updateDetails: () => Promise<void>;
  /**
   * loading
   */
  loading: boolean;
  /**
   * onInvoicePayment
   */
  onInvoicePayment: () => void;

  /**
   * cartId
   */
  cartId: string | null;
}

/**
 * ModalContent component
 */

const ModalContent: React.FC<Props> = ({
  sessionData,
  mode,
  setMode,
  error,
  setError,
  getSession,
  setPayload,
  updateDetails,
  loading,
  onInvoicePayment,
  cartId,
}) => {
  const { i18n } = useI18n();
  const config = useConfig();
  const [validationError, setValidationError] = useState('');
  const { shoppingCartEvents, venue } = useSelector((state) => state);
  const logger = useLogger('cart');
  const shoppingCart = useShoppingCart();
  const shoppingCartApi = useShoppingCartApi();
  const api = useBridgeApi();
  const locale = getLocale();
  const [activatePromoCode, { data, loading: validateLoading }] = useMutation<ActivatePromoCode>(
    ACTIVATE_PROMOCODE,
  );
  const validatePromoCodeHandler = async (code: string) => {
    if (code.length === 0) {
      setMode(ModalType.Hide);
      setValidationError('');
      return;
    }

    await activatePromoCode({
      variables: {
        code,
        companyId: config.COMPANY_ID,
        cartId,
        venueId: venue?.id,
        categoryIds: shoppingCart.items.map((item) => item.categoryId),
      },
    });
    console.log('RUNNING ACTIVATE PROMO CODE');
  };

  useEffect(() => {
    if (loading || !data) {
      console.log('no data', data);
      return;
    }
    if (!loading && data?.activatePromoCode?.success) {
      console.log('success', data?.activatePromoCode);

      const promocode = data?.activatePromoCode?.promoCode;
      logger.info(`Promo code applied: ${promocode?.code}`, { promocode });
      shoppingCartApi.setPromoCode({
        ...promocode,
        discountType: promocode?.discountType as DISCOUNT_TYPE,
        offerType: 'ORDER_DISCOUNT',
        categoryIds: promocode?.categoryIds,
      });

      api.vibrate('impactMedium');

      setValidationError('');

      setMode(ModalType.Hide);
    } else if (!data?.activatePromoCode?.success) {
      console.log('failure', data?.activatePromoCode);

      if (data?.activatePromoCode?.error) {
        setValidationError(i18n(data?.activatePromoCode?.error));
      }
    }
    return;
  }, [data]);

  const commentSubmit = (value: string) => {
    // Replace emojis from comment field with an empty space.
    const filteredComment = value.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
      ' ',
    );
    setMode(ModalType.Hide);
    shoppingCartApi.setComment(filteredComment);
    api.vibrate('impactLight');
  };

  switch (mode) {
    case 'DesiredTime':
      return (
        <Modal
          title={i18n('Date.PickADate')}
          buttonText={i18n('Date.PickADate.OK')}
          onClose={() => {
            setMode(ModalType.Hide);
          }}
          desktopMaxWidth={400}>
          <PreOrderTimePicker
            venueId={venue?.id ?? ''}
            isInCart
            onSubmitted={() => setMode(ModalType.Hide)}
            initialTimePicked={shoppingCart.pickupTime}
          />
        </Modal>
      );

    case 'Comment':
      return (
        <Modal
          title={i18n('Cart.Modal.SendComment')}
          buttonText={i18n('Cart.Modal.SendComment.Button')}
          placeholder={i18n('Cart.Modal.SendComment.Placeholder')}
          value={shoppingCart.comment ?? ''}
          textarea
          onSubmit={(val) => commentSubmit(val)}
          onClose={() => {
            setMode(ModalType.Hide);
          }}
          desktopMaxWidth={500}
          disableButtonTransform></Modal>
      );

    case 'Discount':
      return (
        <Modal
          title={i18n('Cart.Modal.DiscountCode.Title')}
          buttonText={
            validateLoading ? i18n('General.Loading') : i18n('Cart.Modal.DiscountCode.Button')
          }
          placeholder={i18n('Cart.Modal.DiscountCode.Placeholder')}
          value=""
          error={validationError}
          disableButtonTransform={validateLoading}
          disableClose={validateLoading}
          onSubmit={async (val) => {
            await validatePromoCodeHandler(val);
          }}
          onClose={() => {
            setValidationError('');
            setMode(ModalType.Hide);
          }}></Modal>
      );

    case 'ErrorASD':
      return (
        <Modal title={i18n('Cart.Modal.Error.Title')} onClose={() => {}} disableClose={true}>
          <FailureWrapper>
            <ModalText>{i18n('Cart.Modal.Error.Text')}</ModalText>
            <OpacityTouchable>
              <Button
                disabled={loading}
                width="200px"
                text={i18n('Cart.Modal.Error.Button')}
                handleClick={() => {
                  updateDetails();
                }}
              />
            </OpacityTouchable>
          </FailureWrapper>
        </Modal>
      );

    case 'PayLaterOrderPending':
      return (
        <Modal
          baeLogo
          disableClose={true}
          title={i18n('Cart.Modal.PayLaterComplete.Title')}
          onClose={() => {}}>
          <SuccessWrapper>
            <ModalText>{i18n('Cart.Modal.PayLaterComplete.Text')}</ModalText>
          </SuccessWrapper>
        </Modal>
      );

    case 'PayLaterOrderComplete':
      return (
        <Modal
          baeLogo
          disableClose={true}
          title={i18n('Cart.Modal.PayLaterPending.Title')}
          onClose={() => {}}>
          <SuccessWrapper>
            <Loader color="#757578" />
            <ModalText>{i18n('Cart.Modal.PayLaterPending.Text')}</ModalText>
          </SuccessWrapper>
        </Modal>
      );

    case 'PaymentComplete':
      return (
        <Modal
          baeLogo
          title={
            shoppingCart.isFullyPaid
              ? i18n('Cart.Modal.PaymentComplete.Title.Second')
              : i18n('Cart.Modal.PaymentComplete.Title.First')
          }
          onClose={() => {
            setMode(ModalType.Hide);
          }}
          disableClose={true}>
          <SuccessWrapper>
            <Loader color="#757578" />
            <ModalText>
              {shoppingCart.isFullyPaid
                ? i18n('Cart.Modal.PaymentComplete.Text.Second')
                : i18n('Cart.Modal.PaymentComplete.Text.First')}
            </ModalText>
          </SuccessWrapper>
        </Modal>
      );

    case 'AfterPaymentError':
      logger.warn(`Order creation error in cart: ${cartId}`, { events: shoppingCartEvents });
      return (
        <Modal
          title={i18n('Cart.Modal.PaymentError.Title')}
          onClose={() => setMode(ModalType.Hide)}>
          <FailureWrapper>
            <ModalText>{i18n('Error.HANDLE_ORDER_AFTER_PAYMENT_ERROR')}</ModalText>
            <OpacityTouchable>
              <Button
                text={i18n('Cart.Modal.PaymentError.Button')}
                width="200px"
                handleClick={() => {
                  api.vibrate('impactMedium');
                  getSession();
                  setMode(ModalType.Hide);
                  setError('');
                }}
              />
            </OpacityTouchable>
          </FailureWrapper>
        </Modal>
      );

    case 'AdyenDropIn':
      return (
        <Modal
          baeLogo
          disableClose={false}
          title={i18n('Cart.Modal.Payment.Title')}
          large
          hidden={false}
          onClose={() => {
            setMode(ModalType.Hide);
          }}>
          <AdyenDropIn
            key="adyen"
            sessionData={sessionData}
            error={null}
            setShowModal={(m: any) => setMode(m)}
            setPayload={setPayload}
            updateDetails={updateDetails}
            locale={locale}
            onInvoicePayment={onInvoicePayment}
          />
        </Modal>
      );
    case 'nope':
      return (
        <Modal
          baeLogo
          disableClose={false}
          title={i18n('Cart.Modal.Payment.Title')}
          large
          onClose={() => {
            setMode(ModalType.Hide);
          }}>
          <AdyenDropIn
            key="adyen"
            sessionData={sessionData}
            error={i18n('Cart.Modal.AfterPaymentError.Text')}
            setShowModal={(m: any) => setMode(m)}
            setPayload={setPayload}
            updateDetails={updateDetails}
            locale={locale}
            onInvoicePayment={onInvoicePayment}
          />
        </Modal>
      );

    case 'Error':
    case 'PaymentRefused':
    case 'SessionError':
    case 'PaymentError':
      return (
        <Modal
          baeLogo
          disableClose={false}
          title={i18n('Cart.Modal.Payment.Title')}
          large
          onClose={() => {
            setMode(ModalType.Hide);
          }}>
          <AdyenDropIn
            key="adyen"
            sessionData={sessionData}
            error={i18n(error && error.length > 0 ? error : 'Cart.Modal.PaymentError.Text')}
            setShowModal={(m: any) => setMode(m)}
            setPayload={setPayload}
            updateDetails={updateDetails}
            locale={locale}
            onInvoicePayment={onInvoicePayment}
          />
        </Modal>
      );

    default:
      return null;
  }
};

export default ModalContent;
