import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';
import dayjs from 'dayjs';
import { Item as CartItem } from '@baemingo/shopping-cart-events/dist/types';
import { toast } from 'react-toastify';

import { useLogger, useCurrency, useSelector, useDispatch } from 'Hooks';

import Icon from 'Components/Icon';
import { ReactComponent as ClockIcon } from 'Assets/Icons/schedule.svg';
import { ReactComponent as EditIcon } from 'Assets/Icons/create.svg';
import { ReactComponent as StorefrontIcon } from 'Assets/Icons/storefront.svg';
import { lockBody } from 'utils/lockBody';
import getServiceTypeText from 'utils/getServiceTypeText';
import {
  Header,
  Info,
  Total,
  VAT,
  CartItems,
  PaymentButton,
  ModalContent,
  SignUpReminder,
  Tips,
} from './Components';
import { ModalType } from '.';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';
import { useConfig } from 'Components/ConfigProvider';
import { useHistory } from 'react-router-dom';
import * as actions from 'actions';
import OutOfStockModal from './Components/OutOfStockModal/';
import ClearCartAlert from './Components/ClearCartAlert';
import useClearCartAlert from 'Hooks/useClearCartAlert';
import Button from 'Components/Button';

const Wrapper = styled.div`
  height: var(--height);
  overflow: auto;
`;

const PickupToast = styled.div`
  button {
    margin-bottom: 0px;
    margin-top: 4px;
  }
`;

const Content = styled.div<{ withOffer: boolean }>`
  padding-top: ${() => window.headerHeight}px;
  padding-bottom: 88px;
  background-color: #f8f8fb;
  min-height: calc(var(--height) - 68px);
  box-sizing: content-box;
  overflow: auto;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
`;

const MinimumPickupText = styled.p`
  text-align: center;
  padding: 8px;
  margin: 8px 0;
`;

const Inner = styled.div`
  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 960px;
  }
`;

interface Props {
  venueName: string;
  cartId: string | null;
  loading: boolean;
  isVenueOpen: {
    loading: boolean;
    open: boolean;
  };
  error: string | null;
  sessionData: any;
  minimumPickupWaitTime: number;
  setError: React.Dispatch<React.SetStateAction<string>>;
  getSession: () => Promise<void>;
  updateDetails: () => Promise<void>;
  showModal: ModalType;
  setShowModal: React.Dispatch<React.SetStateAction<ModalType>>;
  setPayload: React.Dispatch<React.SetStateAction<string>>;
  tips: number;
  setTips: React.Dispatch<React.SetStateAction<number>>;
  onInvoicePayment: () => void;
  checkCartItemAvailability: () => boolean;
  outOfStockItems: CartItem[];
  setOutOfStockItems: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const CartView: React.FC<Props> = ({
  venueName,
  loading,
  isVenueOpen,
  sessionData,
  showModal,
  setShowModal,
  error,
  setError,
  getSession,
  setPayload,
  updateDetails,
  tips,
  onInvoicePayment,
  setTips,
  checkCartItemAvailability,
  cartId,
  outOfStockItems,
  setOutOfStockItems,
  minimumPickupWaitTime,
}) => {
  const config = useConfig();
  const logger = useLogger('checkout');
  const { i18n } = useI18n();
  const history = useHistory();
  const dispatch = useDispatch();
  const shoppingCart = useShoppingCart();
  const shoppingCartApi = useShoppingCartApi();
  const { formatAmount } = useCurrency();
  const [storageData, updateStorage] = useStorage();
  const isLoggedIn = !!storageData.authToken;
  const qrOrder = useSelector((state) => state.qrOrder);

  const [
    clearCartAlertVisible,
    onShowClearCartAlert,
    onCancelClearCartAlert,
    onConfirmClearCartAlert,
  ] = useClearCartAlert();

  lockBody(false);
  const zeroCartValidation =
    shoppingCart.isPayLaterOrder ||
    Boolean(
      shoppingCart.total <= 0 &&
        shoppingCart.isReadyForCheckout &&
        shoppingCart.totalUnpaid === 0 &&
        shoppingCart.items.length > 0,
    );

  const paymentButtonHandler = () => {
    const cartItemsUnavailable = checkCartItemAvailability();
    // If an item or variation is out of stock we exit the function
    if (cartItemsUnavailable) {
      return;
    }
    if (error) {
      setShowModal(ModalType.SessionError);
    }
    if (shoppingCart.isPayLaterOrder) {
      logger.info('Sending PayLater order', { cartId: storageData.cartId, shoppingCart });
      shoppingCartApi.addClientSidePayment();

      setTimeout(() => {
        setShowModal(ModalType.PayLaterOrderPending);

        setTimeout(() => {
          dispatch(actions.setCartId(null));
          updateStorage({ cartId: null });
          history.push('/');
        }, 2500);
      }, 1600);

      setShowModal(ModalType.PayLaterOrderComplete);
    } else if (zeroCartValidation) {
      logger.info('Adding 0kr payment', { cartId: storageData.cartId, shoppingCart });
      shoppingCartApi.addClientSidePayment();

      setShowModal(ModalType.PaymentComplete);
    } else {
      setShowModal(ModalType.AdyenDropIn);
    }
  };

  const goBackToStart = () => {
    shoppingCartApi.clearPromoCode();
    shoppingCartApi.clearCart();
    dispatch(actions.setCartId(null));
    updateStorage({ cartId: null });
    history.push('/');
  };

  const handlePickupTimeClick = () => {
    if (storageData.hasTimeControlledMenus) {
      // We do not allow the user to change the time if the menu is time controlled, since the products and prices might differ
      toast(
        <PickupToast>
          <p>{i18n('Cart.ChangePickup.NotAllowed')}</p>
          <Button
            text="Start over"
            handleClick={() => onShowClearCartAlert(() => goBackToStart())}
            small
          />
        </PickupToast>,
        {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        },
      );
      return;
    } else {
      setShowModal(ModalType.DesiredTime);
    }
  };

  const showPickupTime = !config.DISABLE_PICKUP_TIME && !shoppingCart.deliveryTargetId && !qrOrder;

  return (
    <Wrapper>
      <Content withOffer={false}>
        <Header restaurant={venueName} serviceType={getServiceTypeText()} />
        <Inner>
          <CartItems />
          <Total price={formatAmount(shoppingCart.total)} discount={shoppingCart.totalDiscount} />
          <VAT />
          {showPickupTime && (
            <Info
              title={
                storageData.hasTimeControlledMenus
                  ? i18n('Cart.Pickuptime')
                  : i18n('Cart.ChangePickuptime')
              }
              text={
                shoppingCart.pickupTime && shoppingCart.pickupTime !== 'ASAP'
                  ? dayjs(shoppingCart.pickupTime).format('DD/MM/YYYY[ ]HH:mm')
                  : i18n('Cart.Pickuptime.EarliestPickup', minimumPickupWaitTime.toString())
              }
              onClick={handlePickupTimeClick}
              icon={<Icon icon={<ClockIcon />} color={'#bdbdc0'} size={24} />}
            />
          )}
          {config.ENABLE_PROMOCODES && (
            <Info
              title={i18n('Cart.Modal.DiscountCode.Title')}
              text={shoppingCart.promoCode?.code ?? i18n('Cart.Modal.DiscountCode.Placeholder')}
              onClick={() => setShowModal(ModalType.Discount)}
              icon={<Icon icon={<StorefrontIcon />} color={'#bdbdc0'} size={24} />}
            />
          )}
          {!config.DISABLE_COMMENTS && (
            <Info
              title={i18n('Cart.AddComment')}
              text={shoppingCart.comment || i18n('Cart.NoComment')}
              onClick={() => setShowModal(ModalType.Comment)}
              icon={<Icon icon={<EditIcon />} color={'#bdbdc0'} size={24} />}
            />
          )}
          {!showPickupTime && !qrOrder && (
            <MinimumPickupText>
              {i18n('Cart.Pickuptime.ReadyInText', minimumPickupWaitTime.toString())}
            </MinimumPickupText>
          )}
          {config.ENABLE_TIPS && shoppingCart.total > 0 && <Tips tips={tips} setTips={setTips} />}
          {!shoppingCart.hasPendingPayment && (
            <PaymentButton
              buttonHandler={paymentButtonHandler}
              isVenueOpen={isVenueOpen}
              gettingSession={loading}
              tips={tips}
            />
          )}
          {!isLoggedIn && <SignUpReminder />}
        </Inner>
      </Content>
      <ModalContent
        sessionData={sessionData}
        setMode={(mode) => setShowModal(mode)}
        mode={showModal}
        error={error}
        getSession={getSession}
        setError={setError}
        setPayload={setPayload}
        updateDetails={updateDetails}
        loading={loading}
        onInvoicePayment={onInvoicePayment}
        cartId={cartId}
      />
      <OutOfStockModal
        visible={Boolean(outOfStockItems.length)}
        items={outOfStockItems}
        onConfirm={() => setOutOfStockItems([])}
      />

      <ClearCartAlert
        visible={clearCartAlertVisible}
        onConfirm={onConfirmClearCartAlert}
        onCancel={onCancelClearCartAlert}
      />
    </Wrapper>
  );
};

export default CartView;
