export type Language = 'se' | 'en' | 'es' | 'no' | 'dk' | 'tr';

export type TranslatedString = {
  id: string;
  se: string;
  en: string;
  no?: string;
  tr?: string;
  dk?: string;
};

export type i18nProps = (obj?: TranslatedString | null | string, ...params: string[]) => string;

export type useI18nProps = () => {
  i18n: i18nProps;
  language: Language | null;
  validate: (key: string) => boolean;
};

export interface Theme {
  main: string;
  secondary: string;
  contrastingColor: string;
  priceColor: string;
  entryBackground: string;
  menuBackgroundColor: string;
  menuTextColor: string;
}

export enum CURRENCY {
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  NOK = 'NOK',
  SEK = 'SEK',
  USD = 'USD',
  TRY = 'TRY',
}
