import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import * as actions from 'actions';
import { useDispatch } from 'Hooks';
import { companyQuery, companyQueryVariables } from './__queries__';
import { Language } from 'Types';
import { useConfig } from 'Components/ConfigProvider';

const COMPANY = gql`
  query companyQuery($id: ID!) {
    company: Company(id: $id) {
      id
      hasMembershipCards
      hasAppOrders
      defaultCurrency
      country
      languages
      defaultLanguage
      enableModifications
      disableVariations
    }
  }
`;

const CompanyManager = () => {
  const config = useConfig();
  const dispatch = useDispatch();

  const { data: companyData } = useQuery<companyQuery, companyQueryVariables>(COMPANY, {
    variables: {
      id: config.COMPANY_ID,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!companyData) {
      return;
    }
    // dispatch the company's default language to state
    dispatch(actions.setDefaultLanguage(companyData?.company.defaultLanguage as Language));
    // dispatch the companys default currency to state
    dispatch(actions.setCompanyCurrency(companyData?.company.defaultCurrency));
    dispatch(actions.setCountry(companyData?.company.country));
    dispatch(actions.setCompanyLanguages(companyData?.company.languages));
    dispatch(
      actions.setCompanyHasMembershipCards(companyData?.company.hasMembershipCards ?? false),
    );
    // hasAppOrders default value is true if the app is enabled, so we dispatch the opposite of that boolean to avoid disabling the app when it shouldnt be
    dispatch(actions.setDisableApp(!companyData.company.hasAppOrders));
    dispatch(
      actions.setEnableModifications(
        !!(companyData?.company?.enableModifications && companyData?.company?.disableVariations),
      ),
    );
  }, [companyData]);

  return null;
};

export default CompanyManager;
